import { Spin } from 'antd'
import { useHistory } from 'react-router-dom'

import { RoutePaths, Routes } from 'app/routes'
import { ChosenPaymentMethodDetails } from 'domain/checkout'
import { ChosenPaymentMethod } from 'domain/checkout/types'
import { SuccessOrderTimeline } from 'domain/createOrder/document'
import { useIsCustomerPostPaid } from 'domain/customers'
import { useOrderQuery } from 'domain/orders'
import { Button, Clipboard, Icon, SuccessMessage } from 'ui'

import OrderSuccessfulAddress from './OrderSuccessfulAddress'
import OrderSuccessfulMessage from './OrderSuccessfulMessage'

interface Props {
  orderId: number | undefined
  orderNumber: number | string | undefined
  payment: ChosenPaymentMethod | null
}

export default function OrderSuccessful({ orderId, orderNumber, payment }: Props) {
  const history = useHistory()
  const isCustomerPostPaid = useIsCustomerPostPaid()
  const { data, isLoading } = useOrderQuery(orderId)

  return (
    <SuccessMessage
      title={
        orderNumber ? (
          <p className="flex">
            Pedido #
            <Clipboard text={orderNumber as string} className="underline" />
            &nbsp;realizado com sucesso
          </p>
        ) : (
          'Pedido realizado com sucesso'
        )
      }
    >
      <div className="flex flex-col gap-4 items-center">
        {payment && <ChosenPaymentMethodDetails payment={payment} />}

        <p>
          Acompanhe o andamento em{' '}
          <a href={RoutePaths.ORDERS} className="underline">
            Meus pedidos
          </a>
        </p>

        {data?.type === 'Certificate' && <SuccessOrderTimeline />}

        {!isCustomerPostPaid && data?.deliveryAddress && (
          <Spin spinning={isLoading}>
            <OrderSuccessfulAddress address={data.deliveryAddress} />
          </Spin>
        )}

        {data?.type === 'AI' && !data.waitingInvoicePayment && (
          <p className="pt-5 pb-7 text-gray-600">
            Seu pedido já está em andamento. Em instantes, a Extração de Dados finalizará a leitura dos
            documentos.
          </p>
        )}

        <OrderSuccessfulMessage className="my-6" />

        <div className="flex gap-4">
          <Button
            type="primary"
            size="large"
            onClick={() => history.push(RoutePaths.CREATE_ORDER)}
            className="mt-8 mb-20 w-64"
            autoFocus
            tabIndex={0}
          >
            <div className="flex items-center justify-center">
              <p>Fazer novo pedido </p> <Icon name="paper-plane" className="ml-2 text-xl" />
            </div>
          </Button>

          {orderId && (
            <Button
              type="primary"
              ghost
              size="large"
              onClick={() => history.push(Routes.Order(orderId))}
              className="mt-8 mb-20 w-64"
              data-id="btnVerPedido"
              autoFocus
              tabIndex={1}
              role="button"
            >
              <div className="flex items-center justify-center">
                <p>Ir para o pedido </p> <Icon name="folder" className="ml-2 text-xl" />
              </div>
            </Button>
          )}
        </div>
      </div>
    </SuccessMessage>
  )
}
