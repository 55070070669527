import { KitItem } from 'domain/kits/types'
import {
  KitOrderCertidaoCEAT,
  KitOrderCertidaoEmpresaCartoriosPJ,
  KitOrderCertidaoImovel,
  KitOrderCertidaoJusticaEstadual,
  KitOrderCertidaoRAISType,
  KitOrderCertidaoSPUDocumentType,
  getServiceConfig,
} from 'domain/servicesCBRdoc/products'
import { CertificateCode } from 'domain/servicesCBRdoc/types'

import { useGetServiceFieldsConfig } from '../../state'
import GenericServiceFields from './GenericServiceFields'
import KitOrderDynamicFields from './KitOrderDynamicFields'

interface Props {
  kitItem: KitItem
}

export default function kitOrderServiceFields({ kitItem }: Props) {
  const config = useGetServiceFieldsConfig(kitItem.id)
  const serviceConfig = getServiceConfig(kitItem.service.code!)

  if (kitItem.service.code === CertificateCode.CERTIDAO_JUSTICA_ESTADUAL) {
    return <KitOrderCertidaoJusticaEstadual kitItem={kitItem} />
  }

  return (
    <>
      {kitItem.service.code === CertificateCode.CERTIDAO_IMOVEL && (
        <KitOrderCertidaoImovel kitItem={kitItem} />
      )}

      {kitItem.service.code === CertificateCode.CERTIDAO_EMPRESA_CARTORIO_PJ && (
        <KitOrderCertidaoEmpresaCartoriosPJ kitItem={kitItem} />
      )}

      {kitItem.service.code === CertificateCode.CERTIDAO_RAIS && (
        <KitOrderCertidaoRAISType kitItem={kitItem} />
      )}

      {!serviceConfig.placeKitDynamicFieldsAtBottom && <KitOrderDynamicFields kitItem={kitItem} />}

      {kitItem.service.code === CertificateCode.CERTIDAO_CEAT && <KitOrderCertidaoCEAT itemId={kitItem.id} />}

      {config!.fields.map(fieldConfig => (
        <GenericServiceFields key={fieldConfig.name} config={fieldConfig} kitItem={kitItem} />
      ))}

      {kitItem.service.code === CertificateCode.CERTIDAO_SPU && (
        <KitOrderCertidaoSPUDocumentType kitItemId={kitItem.id} />
      )}

      {serviceConfig.placeKitDynamicFieldsAtBottom && <KitOrderDynamicFields kitItem={kitItem} />}
    </>
  )
}
