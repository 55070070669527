import { LegalEntity } from 'domain/app/types'
import { QueryKey } from 'react-query'

export const createUseLoadMaritalStatusKey = (
  serviceId: number,
  uf: string | number,
  instance: string,
  model: string | number,
  entity: LegalEntity,
  itemId?: number
): QueryKey => ['services', serviceId, itemId, 'estados-civil', uf, instance, model, entity]
