import Axios from 'axios'

const baseURL = 'https://api.rd.services/platform'

const isProd = process.env.NODE_ENV === 'production'

const baseHeaders = {
  'Content-Type': 'application/json',
}

const RdsAPI = Axios.create({
  baseURL,
  headers: {
    ...baseHeaders,
    Authorization: 'Bearer 26c83628c6774516bf2dbde14310061c',
  },
})

interface BasePayload {
  conversion_identifier: string
  name?: string
  email?: string
  job_title?: string
  state?: string
  city?: string
  country?: string
  personal_phone?: string
  mobile_phone?: string
  twitter?: string
  facebook?: string
  linkedin?: string
  website?: string
  company_name?: string
  company_site?: string
  company_address?: string
  client_tracking_id?: string
  traffic_source?: string
  traffic_medium?: string
  traffic_campaign?: string
  traffic_value?: string
  tags?: string[]
  available_for_mailing?: boolean
}

type ConversionDataPayload = BasePayload | Record<string, any>

interface ConversionData {
  event_type: 'CONVERSION'
  event_family: 'CDP'
  payload: ConversionDataPayload
}

export const RDS = {
  conversionEvent: (data: ConversionData) => {
    if (IGNORE_RDS_TO_USERS.includes(data.payload.email)) return null

    return isProd ? RdsAPI.post('/conversions?api_key=26c83628c6774516bf2dbde14310061c', data) : null
  },
}

export const IGNORE_RDS_TO_USERS = [
  'comercial@cbrdoc.com.br',
  'comercial1@cbrdoc.com.br',
  'felipe@cbrdoc.com.br',
  'andressa@cbrdoc.com.br',
  'cristina@cbrdoc.com.br',
  'felipe.otaviani@cbrdoc.com.br',
  'vinicius@cbrdoc.com.br',
  'michelevalongo@gmail.com',
  'renata@cbrdoc.com.br',
  'jaine@cbrdoc.com.br',
  'alem@cbrdoc.com.br',
  'maura@cbrdoc.com.br',
  'ana@cbrdoc.com.br',
  'vinicius.teixeira@cbrdoc.com.br',
  'larissa.flausino@cbrdoc.com.br',
  'wendel@cbrdoc.com.br',
  'giuliateste@cbrdoc.com.br',
  'larissaqa@cbrdoc.com.br',
  'diligencias@cbrdoc.com.br',
  'kit@cbrdoc.com.br',
  'leila@cbrdoc.br',
  'contateste@cbrdoc.br',
]
