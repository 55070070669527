import { CreateOrderParamsFields } from 'domain/createOrder/types'
import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

type CertidaoJusticaEstadualFields = Record<
  | FieldName.CNPJ
  | FieldName.CPF
  | FieldName.NOME_PF
  | FieldName.NOME_PJ
  | FieldName.URL_UF
  | FieldName.TIPO_PESSOA
  | FieldName.INSTANCIA
  | FieldName.MODELO,
  FieldConfig
>

type CertidaoJusticaEstadualDynamicFields = Record<
  | FieldName.DATA_NASCIMENTO
  | FieldName.ESTADO_CIVIL
  | FieldName.ENDERECO_IMOVEL
  | FieldName.NACIONALIDADE
  | FieldName.NOME_MAE
  | FieldName.NOME_PAI
  | FieldName.NATURALIDADE
  | FieldName.RG
  | FieldName.ENDERECO_IMOVEL
  | FieldName.CEP,
  FieldConfig
>

class CertidaoJusticaEstadual extends Certificate {
  checkDynamicFieldsOnKit = true
  priceWithDynamicFields = true

  formFields: CertidaoJusticaEstadualFields = {
    cnpj: { ...Fields.cnpj, kind: 'cpfOrCnpjAndName' },
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
    modelo: Fields.modelo,
    instancia: Fields.instancia,
    tipo_pessoa: { ...Fields.tipo_pessoa, dataScope: 'service' },
    url_uf: Fields.url_uf,
  }

  dynamicFields: CertidaoJusticaEstadualDynamicFields = {
    rg: { ...Fields.rg, placeholder: 'RG' },
    mae: Fields.mae,
    pai: {
      ...Fields.pai,
      optional: false,
    },
    nascimento: Fields.nascimento,
    naturalidade: Fields.naturalidade,
    nacionalidade: {
      ...Fields.nacionalidade,
      kind: 'select',
      apiExtraInfo: 'nacionalidades',
    },
    estado_civil: {
      ...Fields.estado_civil,
      apiExtraInfo: 'estados-civis',
    },
    endereco: {
      ...Fields.endereco,
      kind: 'text',
      label: 'Endereço Residencial',
      placeholder: 'Endereço Residencial',
      dataScope: 'registree',
    },
    cep: Fields.cep,
  }

  constructor() {
    super({
      id: 26,
      shortName: 'Certidão TJ',
      steps: ['localizacao', 'instancia', 'modelo', 'entidade', 'dados', 'entrega'],
    })
  }

  getAvailableFormatsParams = (
    {
      formItems,
      selectedLocation,
      selectedModel,
      selectedInstance,
      selectedEntityType,
    }: CreateOrderParamsFields,
    itemId = 0
  ) => {
    const params = this.extractLocationUrls(selectedLocation)

    params.tipo_pessoa = formItems[itemId]?.selectedEntityType ?? selectedEntityType ?? 'fisica'
    params.instancia = selectedInstance as string
    params.modelo = selectedModel as string

    return params
  }

  getExtraInfoParams = (createOrder: CreateOrderParamsFields | undefined, itemId = 0) => {
    const { formItems, selectedLocation, selectedModel, selectedInstance, selectedEntityType } = createOrder!

    const params = this.extractLocationUrls(selectedLocation)

    params.tipo_pessoa = formItems[itemId]?.selectedEntityType ?? selectedEntityType ?? 'fisica'
    params.modelo = selectedModel
    params.instancia = selectedInstance

    return params
  }

  getPriceParams = (createOrder: CreateOrderParamsFields, itemId = 0) => {
    const params = this.getAvailableFormatsParams(createOrder)

    if (!params) {
      return null
    }

    const federativeUnit = params.url_uf
    const { formItems, selectedModel, selectedResearchTime } = createOrder

    if (federativeUnit === 'RJ') {
      if (selectedModel === 'titulos-documentos') {
        params.tempo_pesquisa = formItems[itemId]?.selectedResearchTime ?? selectedResearchTime
      }
    }

    params.formato = createOrder.selectedFormat

    return params
  }
}

const service = new CertidaoJusticaEstadual().initialize()

export default service
