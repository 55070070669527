import React from 'react'
import { Radio, Tooltip } from 'antd'

import { ServiceListType } from 'domain/credits/types'
import { ServiceType } from 'domain/servicesCBRdoc/types'
import { Icon } from 'ui'

interface Props {
  value: ServiceType
  onChange: (value: ServiceListType) => void
}

function SelectServiceTypeRadio({ value, onChange }: Props) {
  return (
    <Radio.Group
      value={value}
      onChange={e => {
        onChange(e.target.value)
      }}
    >
      <Radio
        value={ServiceType.CERTIFICATE}
        className="inline-block mr-5 mb-3 sm:mb-0"
      >
        Solicitação de documento{' '}
        <Tooltip
          title="Fazemos a busca e enviamos o documento em formato físico ou digital."
          placement="bottom"
        >
          <div className="inline-block">
            <Icon name="help-tooltip" className="text-primary text-lg ml-1" />
          </div>
        </Tooltip>
      </Radio>

      <Radio value={ServiceType.RESEARCH} className="inline-block">
        Pesquisa
        <Tooltip
          title="Buscamos a localização do documento em instituições de todo o país."
          placement="bottom"
        >
          <div className="inline-block">
            <Icon name="help-tooltip" className="text-primary text-lg ml-1" />
          </div>
        </Tooltip>
      </Radio>
      
      <Radio value={ServiceType.AI} className="inline-block">
        Inteligência artificial
        <Tooltip
          title="Fazemos a análise dos seus documentos utilizando uma inteligência artificial."
          placement="bottom"
        >
          <div className="inline-block">
            <Icon name="help-tooltip" className="text-primary text-lg ml-1" />
          </div>
        </Tooltip>
      </Radio>
      
      <Radio value={ServiceType.DILIGENCE} className="inline-block">
        Diligência
        <Tooltip
          title="Oferecemos serviços de correspondentes jurídicos para várias cidades do país."
          placement="bottom"
        >
          <div className="inline-block">
            <Icon name="help-tooltip" className="text-primary text-lg ml-1" />
          </div>
        </Tooltip>
      </Radio>
    </Radio.Group>
  )
}

export default SelectServiceTypeRadio
