import { CreateOrderParamsFields } from 'domain/createOrder/types'
import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'

const { CODIGO_IMOVEL_RURAL, NATUREZA_JURIDICA, URL_CIDADE, URL_UF } = FieldName

type CertidaoCCIRFields = Record<
  | FieldName.CPF
  | FieldName.CNPJ
  | FieldName.CODIGO_IMOVEL_RURAL
  | FieldName.NATUREZA_JURIDICA
  | FieldName.URL_CIDADE
  | FieldName.URL_UF,
  FieldConfig
>

class CertidaoCCIR extends Certificate {
  formFields: CertidaoCCIRFields = {
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    [NATUREZA_JURIDICA]: {
      ...Fields[NATUREZA_JURIDICA],
      stateKey: 'selectedType',
      showIf: ({ tipo_pessoa }) => tipo_pessoa === 'juridica'
    },
    [URL_UF]: Fields[URL_UF],
    [URL_CIDADE]: Fields[URL_CIDADE],
    [CODIGO_IMOVEL_RURAL]: Fields[CODIGO_IMOVEL_RURAL],
  }

  constructor() {
    super({
      shoudAutoLoadDynamicFields: true,
      id: 95,
      shortName: 'CCIR',
      steps: ['localizacao', 'dados', 'entrega'],
    })
  }

  getExtraInfoParams = (createOrder: CreateOrderParamsFields) => {
    const { selectedLocation } = createOrder
    const params = this.extractLocationUrls(selectedLocation)

    params.tipo_pessoa = 'juridica'

    return params
  }

  getAvailableFormatsParams = (createOrder: CreateOrderParamsFields, itemId: string | number = '0') => {
    const { formItems, selectedLocation } = createOrder
    const params = this.extractLocationUrls(selectedLocation)

    params.tipo_pessoa = formItems[itemId].selectedEntityType ?? 'fisica'

    if (params.tipo_pessoa === 'juridica') {
      params.natureza_juridica = formItems[itemId].selectedType
    }

    return params
  }

  getPriceParams = (createOrder: CreateOrderParamsFields, itemId: string | number = '0') => {
    const params = this.getAvailableFormatsParams(createOrder, itemId)

    params.formato = createOrder.selectedFormat

    return params
  }
}

const service = new CertidaoCCIR().initialize()

export default service
