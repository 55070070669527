import { useQuery } from 'react-query'
import { getUFFields } from './request'
import { SelectedService } from 'domain/createOrder/types'
import { createUseLoadUFFieldsKey } from './key'
import { LegalEntity } from 'domain/app/types'

interface Params {
  service: SelectedService
  itemId?: number
  onSuccess?: () => void
  enabled?: boolean
  params: { uf: string | number; instance: string; model: string | number; entity: LegalEntity }
}

export const useLoadUFFields = ({ service, enabled, itemId, onSuccess, params }: Params) => {
  const { uf, instance, model, entity } = params

  return useQuery(
    createUseLoadUFFieldsKey(service.id, uf, instance, model, entity, itemId),
    () => getUFFields({ service, uf, instance, model, entity }),
    {
      enabled,
      onSuccess,
    }
  )
}
