import { useMemo } from 'react'
import { Dropdown, MenuProps } from 'antd'

import { useLatestNotificationsQuery } from 'domain/notifications/services'
import { useSetLatestView } from 'domain/notifications/state'
import { ErrorBoundary } from 'ui'

import AllNotificationsButton from './AllNotificationsButton'
import TopBarNotificationCard from './TopBarNotificationsCard'
import TopBarNotificationsBadge from './TopBarNotificationsBadge'

export default function TopBarNotifications() {
  const { data: notifications } = useLatestNotificationsQuery()
  const setLatestView = useSetLatestView()
  
  const sortedNotifications = useMemo(() => {
    return notifications?.slice().sort((a, b) => 
      b.createdAt.getTime() - a.createdAt.getTime()
    )
  }, [notifications])

  const menu = useMemo((): MenuProps => {
    const items: any = sortedNotifications?.length ?
      sortedNotifications.map(notification => ({
        key: notification.id,
        label: (
          <ErrorBoundary>
            <TopBarNotificationCard notification={notification} />
          </ErrorBoundary>
        ),
        className: 'border-b border-gray-200',
      }))
      :
      [{
        key: 'none',
        label: <>Não há notificações recentes.</>,
        className: 'border-b border-gray-200 italic cursor-default',
        disabled: true,
      }]

    items.push({
      key: 'all',
      className: 'bottom-[1px] border-t border-gray-200 bg-white sticky',
      label: <AllNotificationsButton />,
    })

    return { items }
  }, [notifications])

  const onOpen = (open: boolean) => {
    if (open) {
      setLatestView((new Date()).toISOString())
    }
  }

  return (
    <Dropdown 
      overlayClassName="[&>ul]:pb-0 [&>ul]:max-h-[75vh] [&>ul]:small-scroll [&>ul]:overflow-auto"
      onOpenChange={onOpen}
      placement="bottom"
      trigger={trigger}
      menu={menu}
    >
      <div className="flex items-center cursor-pointer ml-1">
        <TopBarNotificationsBadge notifications={notifications} />
      </div>
    </Dropdown>
  )
}

const trigger: any = ['click']
