import { Form, Spin } from 'antd'
import { Section } from 'layouts'
import { RadioGroupField } from 'domain/servicesCBRdoc/fields'
import { Fields } from 'domain/servicesCBRdoc'
import { useDispatch } from 'react-redux'
import { SubmitButton } from 'ui'
import { useQueryClient } from 'react-query'
import { createOrderActions, useCreateOrderNextStep, useCreateOrderState } from 'domain/createOrder/document'
import { useLoadUFModels } from 'domain/createOrder/document/services/useLoadUFModels'
import { createUseLoadUFEntitiesKey } from 'domain/createOrder/document/services/useLoadUFEntities/key'
import { getUFEntities } from 'domain/createOrder/document/services/useLoadUFEntities/request'

export const SelectModelStep = () => {
  const { selectedService, selectedLocation, selectedInstance, selectedModel } = useCreateOrderState()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [goToNextStep] = useCreateOrderNextStep('modelo')

  const final_uf = Array.isArray(selectedLocation?.url_uf)
    ? selectedLocation?.url_uf?.[0].value
    : selectedLocation?.url_uf?.value

  const { data, isLoading, isError } = useLoadUFModels({
    service: selectedService,
    params: { uf: final_uf as string, instance: selectedInstance ?? '' },
  })

  const handleChange = (value: string) => {
    dispatch(createOrderActions.setSelectedModel(value))

    queryClient.prefetchQuery(
      createUseLoadUFEntitiesKey(selectedService.id, final_uf as string, selectedInstance ?? '', value),
      () =>
        getUFEntities({
          service: selectedService,
          instance: selectedInstance ?? '',
          model: value,
          uf: final_uf as string,
        })
    )
  }

  return (
    <Spin spinning={isLoading}>
      <Section heading="Selecione o modelo de ação que melhor corresponde ao seu pedido" className="pt-4">
        <Form
          form={form}
          initialValues={{
            [Fields.modelo.name]: selectedModel,
          }}
          onFinish={goToNextStep}
          layout="vertical"
          className="pb-24 pt-4"
          requiredMark={false}
        >
          <RadioGroupField
            vertical
            field={{ ...Fields.modelo, label: '' }}
            onChange={handleChange}
            options={data}
            hasError={isError}
          />
          <SubmitButton disabled={!selectedModel} />
        </Form>
      </Section>
    </Spin>
  )
}
