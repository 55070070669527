import { useMemo } from 'react'
import { useGetOrderItem } from 'domain/orderItem/shared'
import { getServiceConfig } from 'domain/servicesCBRdoc'

export function useExtractedSummaryAndSections() {
  const { extractSummary, service } = useGetOrderItem()
  const { extractedReportSections } = getServiceConfig(service.code)
  return useMemo(() => {
    if (!extractSummary || !extractedReportSections) return []

    const filterNonNull = (data: Record<string, any>) => {
      return Object.entries(data)
        .filter(([, value]) => value != null)
        .reduce((acc, [key, value]) => {
          acc[key] = value
          return acc
        }, {} as Record<string, unknown>)
    }

    const hasArea = !!extractSummary.area

    const summary = hasArea
      ? {
        area:
          typeof extractSummary.area === 'string'
            ? extractSummary.area
            : extractSummary.area
              ? extractAreaObject(extractSummary.area)
              : undefined,
        owners: extractSummary.proprietarios?.length ?? 0,
        gravames: extractSummary.gravames_ativos?.length ?? 0,
      }
      : null

    const extracted = {
      ...filterNonNull({
        ...extractSummary,
        area: extractAreaObject(extractSummary.area),
        summary,
      }),
    }

    return [extracted, extractedReportSections] as [any, any]
  }, [extractSummary, extractedReportSections])
}

function extractAreaObject(area: unknown) {
  let areaValue = ''

  if (!area || typeof area !== 'object') {
    return areaValue
  }

  Object.entries(area).forEach(([key, value]: any) => {
    if (key.includes('total')) {
      areaValue = value
    }
  })
  return areaValue
}
