import React from 'react'

import { OrderStatuses, StatusCode } from 'domain/orders/types'
import { Alert, Icon } from 'ui'

import HelpLine from './HelpLine'

interface Props {
  alertType?: 'success' | 'warning' | 'error' | 'info'
  children?: React.ReactNode
  icon?: JSX.Element
  message?: string
  status?: StatusCode
  title?: string
}

function FeedbackMessage({ alertType, icon, title, message, children, status }: Props) {
  return (
    <div className="rounded-lg mr-6 ml-8 bg-white">
      <div className="p-7 flex flex-col flex-1 items-center">
        {icon}
        <h1
          className={`font-bold text-2xl mb-5 ${icon ? '' : 'mt-10'} ${
            alertType === 'error' ? 'pt-6' : ''
          }`}
        >
          {status ? OrderStatuses[status].name : title}
        </h1>
        {message && (
          <Alert
            type={alertType || 'success'}
            icon={alertType !== 'success' && <Icon name={alertType === 'error' ? 'warning' : 'email'} />}
            className="max-w-md"
            message={message}
          />
        )}
        {children}
        <HelpLine />
      </div>
    </div>
  )
}

export default FeedbackMessage
