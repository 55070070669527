import { OrderNameField, useCreateOrderState } from 'domain/createOrder/document'

import AlertDocumentsRequired from './AlertDocumentsRequired'
import CertidaoJusticaEstadualFields from './CertidaoJusticaEstadualFields'
import { useLoadFormFields } from 'domain/servicesCBRdoc/services/useLoadFormFields'
import { useMemo } from 'react'

interface Props {
  itemId: number
}

export default function CertidaoJusticaEstadualForm({ itemId }: Props) {
  const orderState = useCreateOrderState()
  const { selectedEntityType, selectedInstance, selectedLocation, selectedModel, selectedService } =
    orderState

  const { data, isLoading } = useLoadFormFields({
    selectedEntityType: selectedEntityType ?? 'fisica',
    selectedService,
    url_uf: selectedLocation.url_uf,
    itemId,
    selectedInstance: selectedInstance ?? '',
    selectedModel: selectedModel ?? '',
  })

  const final_uf = Array.isArray(selectedLocation?.url_uf)
    ? selectedLocation?.url_uf?.[0].value
    : selectedLocation?.url_uf?.value

  const fields = useMemo(() => {
    if (selectedEntityType !== 'juridica' || !data) return data

    const fieldsWithoutCnpj = data.filter(field => field.kind !== 'name')

    return fieldsWithoutCnpj
  }, [data, selectedEntityType])

  return (
    <>
      <CertidaoJusticaEstadualFields itemId={itemId} loading={isLoading} fields={fields} />

      <OrderNameField itemId={itemId} />

      <AlertDocumentsRequired
        entityType={selectedEntityType}
        federativeUnit={final_uf}
        instance={selectedInstance}
      />
    </>
  )
}
