import React, { useCallback, useEffect, useState } from 'react'
import { Form, Checkbox, Button } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { RoutePaths } from 'app/routes'
import { Alert, EmailInput, PasswordInput, ScrollableErrorAlert } from 'ui'
import useLogin from '../services/useLogin'
import { AuthorizedAPI } from 'api/network/v2'

type FormData = {
  email: string
  password: string
  keepMeLoggedIn: boolean
}

export default function LoginForm() {
  const location = useLocation<any>()
  const [form] = Form.useForm<FormData>()
  const { login, error, status } = useLogin(location?.state?.from?.pathname)
  const isLoading = status === 'loading'
  const accountCreated = location.search.includes('conta-criada')
  const passwordRedefined = location.search.includes('senha-redefinida')
  const [captchaError, setCaptchaError] = useState<any | undefined>()

  const handleSubmit = async (token: string) => {
    try {
      const res = await AuthorizedAPI.post('/login/validate-captcha', {
        token,
      })
      const data = res.data

      if (!data.success) {
        setCaptchaError({ message: 'Erro no login', description: 'A solicitação é inválida ou está incorreta.'})
      }

    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Form form={form} onFinish={login} layout="vertical" requiredMark={false}>
      {error && <ScrollableErrorAlert error={error} />}
      {captchaError && <ScrollableErrorAlert error={captchaError} />}

      {accountCreated && !error && (
        <Alert type="success" message="Conta criada com sucesso" description="Você já pode fazer seu login" />
      )}

      {passwordRedefined && !error && (
        <Alert type="success" message="Senha alterada com sucesso" description="Pode fazer seu login" />
      )}

      <EmailInput required />

      <div className="relative">
        <Link
          className="text-sm underline absolute right-0 top-0 cursor-pointer z-10"
          to={RoutePaths.FORGOT_PASSWORD}
        >
          Esqueci minha senha
        </Link>
      </div>

      <PasswordInput disabled={isLoading} className="border-gray-500" required />

      <Form.Item name="keepMeLoggedIn" valuePropName="checked">
        <Checkbox>Continuar conectado</Checkbox>
      </Form.Item>
      
      <ReCaptchaComponent handleToken={handleSubmit} />

      <Button
        type="primary"
        htmlType="submit"
        size="large"
        className={`text-lg mb-2 flex flex-row justify-center items-center ${
          isLoading ? 'shadow-inner bg-gray-600 text-white' : ''
        }`}
        block
        disabled={isLoading}
        loading={isLoading}
      >
        Entrar
      </Button>

      <p className="text-sm text-gray-400 text-center h-5 mb-2">
        {isLoading ? 'Verificando credenciais...' : ''}
      </p>
    </Form>
  )
}

const ReCaptchaComponent = ({ handleToken }: { handleToken: (token: string) => void }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    const token = await executeRecaptcha()
    handleToken(token)
  }, [executeRecaptcha])

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify()
  }, [handleReCaptchaVerify])

  return null
}
