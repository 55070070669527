import { Form, Spin } from 'antd'
import { Section } from 'layouts'
import { Fields } from 'domain/servicesCBRdoc'
import { RadioGroupField } from 'domain/servicesCBRdoc/fields'
import { SubmitButton } from 'ui'
import { useDispatch } from 'react-redux'
import { LegalEntity } from 'domain/app/types'

import { useQueryClient } from 'react-query'
import { useLoadUFEntities } from 'domain/createOrder/document/services/useLoadUFEntities'
import { createUseLoadUFFieldsKey } from 'domain/createOrder/document/services/useLoadUFFields/key'
import { getUFFields } from 'domain/createOrder/document/services/useLoadUFFields/request'
import { useCreateOrderState, useCreateOrderNextStep, createOrderActions } from 'domain/createOrder/document'

export const SelectEntityStep = () => {
  const { selectedService, selectedLocation, selectedInstance, selectedModel, selectedEntityType } =
    useCreateOrderState()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [goToNextStep] = useCreateOrderNextStep('entidade')

  const final_uf = Array.isArray(selectedLocation?.url_uf)
    ? selectedLocation?.url_uf?.[0].value
    : selectedLocation?.url_uf?.value

  const { data, isLoading, isError } = useLoadUFEntities({
    service: selectedService,
    params: {
      uf: final_uf as string,
      instance: selectedInstance ?? '',
      model: (selectedModel as string) ?? '',
    },
  })

  const handleChange = (value: string) => {
    const finalValue: LegalEntity = value === 'PF' ? 'fisica' : 'juridica'

    dispatch(createOrderActions.setSelectedEntityType(finalValue))
    dispatch(createOrderActions.setMultipleItemsLegalEntity({ id: 0, value: finalValue }))

    queryClient.prefetchQuery(
      createUseLoadUFFieldsKey(
        selectedService.id,
        final_uf as string,
        selectedInstance ?? '',
        (selectedModel as string) ?? '',
        finalValue
      ),
      () =>
        getUFFields({
          service: selectedService,
          uf: final_uf as string,
          instance: selectedInstance ?? '',
          model: (selectedModel as string) ?? '',
          entity: finalValue,
        })
    )
  }

  return (
    <Spin spinning={isLoading}>
      <Section heading="Selecione o tipo de pessoa" className="pt-4">
        <Form
          form={form}
          layout="vertical"
          onFinish={goToNextStep}
          className="pb-24 pt-4"
          requiredMark={false}
        >
          <RadioGroupField
            field={{ ...Fields.tipo_pessoa, label: '' }}
            onChange={handleChange}
            options={data}
            hasError={isError}
          />
          <SubmitButton disabled={!selectedEntityType} />
        </Form>
      </Section>
    </Spin>
  )
}
