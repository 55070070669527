import React from 'react'
import { useDispatch } from 'react-redux'
import { Spin } from 'antd'

import { createOrderActions, useCreateOrderState } from 'domain/createOrder/document/state'
import { DeliveryFormat } from 'domain/createOrder/types'
import { CardButton } from 'ui'
import { IconName } from 'ui/Icon/Icon'

const SelectFormat = () => {
  const dispatch = useDispatch()

  const { availableFormats, selectedFormat } = useCreateOrderState()

  if (!availableFormats) {
    return <Spin className="w-full text-center" />
  }

  return (
    <div className="flex flex-wrap">
      {formats.map(format => {
        const { code, title, subTitle, icon, tabIndex } = format
        const hasFormat = availableFormats.includes(code)

        return (
          hasFormat && (
            <div key={code} className="w-full xl:w-1/2 2xl:w-1/3 p-2 flex items-strech">
              <CardButton
                title={title}
                icon={icon}
                checked={selectedFormat === code}
                subTitle={subTitle}
                onClick={() => {
                  dispatch(createOrderActions.setSelectedFormat(code))
                }}
                tabIndex={tabIndex}
              />
            </div>
          )
        )
      })}
    </div>
  )
}

const formats: {
  code: DeliveryFormat
  icon: IconName
  secondIcon?: IconName
  title: string
  subTitle: string
  tabIndex: number
}[] = [
  {
    code: 'papel',
    icon: 'file',
    title: 'Papel',
    subTitle: 'Além da prévia digitalizada, você receberá sua certidão em papel no seu endereço.',
    tabIndex: 2,
  },
  {
    code: 'email',
    icon: 'file-pdf',
    title: 'E-mail',
    subTitle: 'Você receberá sua certidão em formato digital (PDF) por e-mail.',
    tabIndex: 3,
  },
  {
    code: 'combo',
    icon: 'file-pdf',
    secondIcon: 'file',
    title: 'Combo',
    subTitle:
      'Além da prévia digitalizada, você receberá sua certidão em papel no seu endereço e em formato digital (PDF) por e-mail.',
    tabIndex: 4,
  },
]

export default SelectFormat
