import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { joinStrings } from 'utils/formatters'

type CertidaoStmCriminaisFields = Record<
  FieldName.CPF | FieldName.DATA_NASCIMENTO | FieldName.NOME_MAE | FieldName.NOME_PF,
  FieldConfig
>

const { CPF, DATA_NASCIMENTO, FORMATO, NOME_MAE, NOME_PF } = FieldName

class CertidaoSTMCriminais extends Certificate {
  formFields: CertidaoStmCriminaisFields = {
    [CPF]: Fields[CPF],
    [NOME_PF]: Fields[NOME_PF],
    [NOME_MAE]: Fields[NOME_MAE],
    [DATA_NASCIMENTO]: Fields[DATA_NASCIMENTO],
  }

  constructor() {
    super({
      id: 36,
      priceByFields: [FORMATO],
      shortName: 'STM - Criminais',
      steps: ['dados', 'entrega'],
      extractedReportSections: [
        {
          title: 'Dados da certidão',
          properties: [
            { label: 'Status da certidão', key: 'status_certidao' },
            { label: 'Data da certidão', key: 'data_emissao' },
            { label: 'Réu', key: 'nome_reus', formatter: joinStrings },
          ],
        },
        {
          title: '',
          properties: [
            { label: 'Classe / Situação', key: ['classe', 'situacao'] },
            { label: 'Órgão Julgador / Relator', key: 'orgao_julgador' },
            { label: 'Assunto', key: 'assunto' },
            { label: 'Data da distribuição', key: 'data_distribuicao' },
            { label: 'Apelante', key: 'apelantes' },
          ],
          propertiesTitleFrom: 'numero',
          fromArray: 'processos'
        }
      ],
    })
  }
}

const service = new CertidaoSTMCriminais().initialize()

export default service
