import { FieldConfig } from 'domain/servicesCBRdoc/fields/types'

import DateField from './DateField'
import MaskedField from './MaskedField'
import NumberField from './NumberField'
import TextField from './TextField'
import TextAreaField from './TextAreaField'
import NameField from './NameField'
import CNPJAndNameFields from './CNPJAndNameFields'
import { Fields } from 'domain/servicesCBRdoc/config'
import { CPFInput } from 'ui'

interface Props {
  field: FieldConfig
  itemId?: number
  autoFocus?: boolean
}

export default function DynamicTextField({ field, itemId, autoFocus }: Props) {
  if (field.kind === 'date') {
    return <DateField itemId={itemId} field={field} autoFocus={autoFocus} />
  }

  if (field.kind === 'cpfOrCnpjAndName') {
    return (
      <CNPJAndNameFields
        label={field.label}
        companyField={Fields.nome_pj}
        autoFocus={autoFocus}
        itemId={itemId}
      />
    )
  }

  if (field.kind === 'cpfOrCnpj') {
    return <CPFInput label={field.label} itemId={itemId} autoFocus={autoFocus} />
  }

  if (field.mask) {
    return <MaskedField itemId={itemId} field={field} autoFocus={autoFocus} />
  }

  if (field.kind === 'number') {
    return <NumberField itemId={itemId} field={field} autoFocus={autoFocus} />
  }

  if (field.kind === 'name') {
    return <NameField itemId={itemId} field={field} autoFocus={autoFocus} />
  }

  if (field.kind === 'text') {
    return <TextField itemId={itemId} field={field} autoFocus={autoFocus} placeholder={field.placeholder} />
  }

  if (field.kind === 'multiline') {
    return <TextAreaField itemId={itemId} field={field} autoFocus={autoFocus} />
  }

  return null
}
