import { Descriptions, DescriptionsProps, Table, TableColumnsType, Tabs } from 'antd'
import { OCRComparison } from 'domain/myFiles'
import { OpenAIEnrichment } from 'domain/openAI/enrich'
import ViewCertificateActions from 'domain/orderItem/certificate/components/ViewCertificateActions'
import ViewCertificateStatus from 'domain/orderItem/certificate/components/ViewCertificateStatus'
import { useGetOrderItem, ViewOrderLayout } from 'domain/orderItem/shared'
import {
  ExtractedSummary,
  ExtractedSummaryProcesses,
  ExtractedSummaryProcessLabels,
} from 'domain/orderItem/typesAPI'
import { useMemo, useState } from 'react'
import { Button, ViewPDF } from 'ui'
import { DISPLAY_DATE_FORMAT, isAfter, parseDate, validateDate } from 'utils/dateTime'

export default function ViewCertidaoJusticaEstadual() {
  const { fileURL, ocr, service, extractSummary } = useGetOrderItem()
  const [activeTab, setActiveTab] = useState('report')

  const tabItems = useMemo(() => {
    const items = []

    if (extractSummary) {
      items.push({
        label: 'Ficha',
        key: 'report',
        children: <ViewReport summary={extractSummary} />,
      })
    }

    return [
      ...items,
      {
        label: 'Original',
        key: 'original',
        children: (
          <>
            <div className="row flex flex-1 h-full">
              <ViewPDF fileUrl={fileURL!} />,
            </div>
          </>
        ),
      },
      {
        label: 'Transcrição',
        key: 'transcript',
        children: <OCRComparison pdfUrl={fileURL!} ocr={ocr} />,
      },
      {
        label: 'Extração de Dados',
        key: 'openAI',
        children: <OpenAIEnrichment fileUrl={fileURL!} ocr={ocr!} serviceId={service.id} />,
      },
    ]
  }, [fileURL, ocr, service, extractSummary])

  return (
    <ViewOrderLayout sidebarActions={<ViewCertificateActions />}>
      <ViewCertificateStatus>
        <Tabs
          defaultActiveKey={activeTab}
          className="mt-3"
          tabBarStyle={{ marginLeft: '1rem' }}
          items={tabItems}
          onChange={setActiveTab}
        />
      </ViewCertificateStatus>
    </ViewOrderLayout>
  )
}

interface ViewReportProps {
  summary: ExtractedSummary
}

function ViewReport({ summary }: ViewReportProps) {
  const { columns, dataSource } = useMemo(() => {
    const columns: TableColumnsType<
      Pick<ExtractedSummaryProcesses, 'numero_processo' | 'data_distribuicao' | 'comarca'> & {
        expandable: DescriptionsProps['items']
      }
    > = [
      {
        key: 'numero_processo',
        title: 'Número do processo',
        dataIndex: 'numero_processo',
        sorter: (a, b) => {
          if (a.numero_processo && b.numero_processo) {
            return a.numero_processo.length - b.numero_processo.length
          }
          return 0
        },
      },
      {
        title: 'Data distribuicao',
        dataIndex: 'data_distribuicao',
        key: 'data_distribuicao',
        sorter: (a, b) => {
          if (validateDate(a.data_distribuicao) && validateDate(b.data_distribuicao)) {
            if (
              isAfter(
                parseDate(a.data_distribuicao!, DISPLAY_DATE_FORMAT).toDate(),
                parseDate(b.data_distribuicao!, DISPLAY_DATE_FORMAT).toDate()
              )
            ) {
              return 1
            } else {
              return -1
            }
          }
          return 0
        },
      },
      {
        title: 'Comarca',
        dataIndex: 'comarca',
        key: 'comarca',
      },
      {
        title: '',
        key: 'actions'
      },
    ]

    const dataSource: any = summary.processos?.map(
      ({ numero_processo, data_distribuicao, comarca, ...rest }, index) => ({
        key: index,
        numero_processo,
        data_distribuicao,
        comarca,
        expandable: Object.entries(rest)
          .filter(([key]) => ExtractedSummaryProcessLabels[key])
          .map(([key, value]) => ({
            key,
            label: ExtractedSummaryProcessLabels[key],
            children: <p>{value}</p>,
          })),
      })
    )
    return {
      columns,
      dataSource,
    }
  }, [summary])
  const statusNegative = summary.processos?.map(p => p.status).includes('negativa')

  return (
    <div className="mx-6">
      <section className="mt-8">
        <h2 className="text-secondary-500 font-bold text-lg">Dados da certidão</h2>
        <div className="flex gap-4 my-6">
          <p className="basis-1/4">Status da certidão</p>
          <div className={`flex gap-2 text-${statusNegative ? 'blue-500' : 'error'} capitalize`}>
            <div className={`rounded-full w-3 h-3 bg-${statusNegative ? 'blue-500' : 'error'}`}></div>
            {statusNegative ? 'Negativa' : 'Positiva'}
          </div>
        </div>
      </section>
      <section>
        <h2 className="text-secondary-500 font-bold text-lg mb-6">Processos</h2>
        <Table
          columns={columns}
          expandable={{
            expandedRowRender: ({ expandable }) => (
              <Descriptions
                items={expandable}
                labelStyle={{ color: '#4C566F', fontWeight: 700, fontSize: 14 }}
              />
            ),
            fixed: 'right',
            expandIconColumnIndex: 3,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <Button
                  style={{ float: 'right' }}
                  onClick={e => onExpand(record, e)}
                  icon="chevron-up"
                  type="link"
                >
                  Ocultar
                </Button>
              ) : (
                <Button
                  style={{ float: 'right' }}
                  onClick={e => onExpand(record, e)}
                  icon="chevron-down"
                  type="link"
                >
                  Ver mais
                </Button>
              ),
          }}
          dataSource={dataSource}
        />
      </section>
    </div>
  )
}
