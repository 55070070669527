import { Tooltip } from 'antd'
import { OrderItem } from 'domain/orderItem/types'
import { orderDetailsLabels } from 'domain/servicesCBRdoc/fields/types'
import useExtractOrderDetails from '../helpers/useExtractOrderDetails'

export const fullOrderInfo = (orderItem: OrderItem) => {
  const orderInfo = useExtractOrderDetails(orderItem)

  const fullOrder: { [key: string]: string | number | boolean } = {
    local_cartorio:
      Array.isArray(orderItem?.locationInfo?.url_cartorio)
        ? orderItem?.locationInfo?.url_cartorio[0]?.label 
        : orderItem?.locationInfo?.url_cartorio?.label!,
    local_cidade: 
      Array.isArray(orderItem?.locationInfo?.url_cidade)
        ? orderItem?.locationInfo?.url_cidade[0]?.label 
        : orderItem?.locationInfo?.url_cidade?.label!,
    cpf: orderItem?.submitData?.cpf,
    ...orderItem.submitData
  }

  const formatOrderValue = (label: string, value: string) => {
    const parsedValue = orderInfo.find(value => value[0] == label)?.[1] ?? value

    return (
      <Tooltip title={parsedValue || 'Sem informação'} placement="top">
        <div>{parsedValue || 'Sem informação'}</div>
      </Tooltip>
    )
  }

  return (
    <div className="order-item-summary p-0">
      {Object.keys(fullOrder).map((key) => (
        orderDetailsLabels[key] && fullOrder[key] && (
          <div key={key}>
            {orderDetailsLabels[key]}:
            {formatOrderValue(orderDetailsLabels[key], String(fullOrder[key]))}
          </div>
        ))
      )}
    </div>
  )
}
