import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { LegalEntity } from 'domain/app/types'
import { CreateOrderForm } from 'domain/createOrder/types'
import { setStorageData } from 'utils/localStorage'
import { KitServicesForm } from '../types'
import { kitAtom } from './kit'

export const KIT_ORDER_SERVICES_DATA = 'kit_order_details'

export const servicesFormDataAtom = atom<KitServicesForm>({})

const serviceFormDataAtom = atomFamily((itemId: number) => {
  return atom(
    get => get(servicesFormDataAtom)[itemId] ?? ({} as CreateOrderForm),
    (get, set, newValue: CreateOrderForm) => {
      const formData = get(servicesFormDataAtom)

      const updated = {
        ...formData,
        [itemId]: {
          ...formData[itemId],
          ...newValue,
        },
      }

      set(servicesFormDataAtom, updated as KitServicesForm)
    }
  )
})

export const useServiceForm = (itemId: number) => useAtom(serviceFormDataAtom(itemId))
export const useServicesForm = () => useAtom(servicesFormDataAtom)
export const useGetServiceForm = (itemId: number) => useAtomValue(serviceFormDataAtom(itemId))
export const useGetServicesForm = () => useAtomValue(servicesFormDataAtom)
export const useSetServicesForm = () => useSetAtom(servicesFormDataAtom)
export const useUpdateServiceForm = (itemId: number) => useSetAtom(serviceFormDataAtom(itemId))

export const legalEntityAtom = atom(
  get => {
    const selected = get(servicesFormDataAtom).tipo_pessoa
    if (selected) return selected

    const kitEntity = get(kitAtom).legalEntity

    return kitEntity !== 'indefinido' ? kitEntity : 'fisica'
  },
  (get, set, newValue: LegalEntity) => {
    set(servicesFormDataAtom, { ...get(servicesFormDataAtom), tipo_pessoa: newValue })
  }
)

export const useGetLegalEntity = () => useAtomValue(legalEntityAtom)
export const useSetLegalEntity = () => useSetAtom(legalEntityAtom)

export const useSaveServiceFormOnLocalStorage = () => {
  const setFormData = useSetServicesForm()

  const debounced = useDebouncedCallback((changedValues: any, allValues: any) => {
    setFormData(allValues)
    setStorageData(KIT_ORDER_SERVICES_DATA, allValues)
  }, 500)

  useEffect(
    () => () => {
      debounced.flush()
    },
    [debounced]
  )

  return debounced
}
