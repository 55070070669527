import { useMemo } from 'react'
import { Divider } from 'antd'

import { ServiceTypeDescription } from 'domain/servicesCBRdoc/data'
import { ServiceType } from 'domain/servicesCBRdoc/types'
import { formatMoney, singularOrPlural } from 'utils/formatters'

import { useShoppingCartState } from '../state'
import CartCheckoutButton from './CartCheckoutButton'

export default function CartResume() {
  const { orders, totalPrice } = useShoppingCartState()

  const resumeList = useMemo(() => {
    const items = new Map<string, number>()
    orders.forEach(order => {
      if (order.selectedService.type) {
        const key = order.selectedService.type
        const total = items.get(key) ?? 0
        items.set(key, total + 1)
      }
    })

    const keys = items.keys()
    const list = [] as any[]
    for (const key of keys) {
      list.push({
        label: `${ServiceTypeDescription[key as ServiceType]} (${singularOrPlural(
          items.get(key) || 0,
          'item',
          'itens'
        )})`,
        value: orders.reduce((acc, curr) => {
          if (!curr.selectedService.type) return acc
          if (curr.selectedService.type !== key) return acc
          return acc + (curr.orderDetails.totalValue ? curr.orderDetails.totalValue : 0)
        }, 0),
      })
    }

    return list
  }, [orders])

  return (
    <>
      <div className="mt-4 mx-2 p-6 bg-white rounded-lg w-[349px] h-fit">
        <h2 className="font-bold text-lg">Resumo</h2>

        <ul>
          {resumeList.map(item => (
            <li key={item.label} className="flex items-center justify-between my-2">
              <span className="text-sm font-semibold">{item.label}</span>
              <span className="font-semibold">{formatMoney(item.value)}</span>
            </li>
          ))}
        </ul>

        <Divider className="my-2" />
        <div>
          <div className="flex items-center justify-between">
            <span className="text-sm font-semibold">Total:</span>
            <span className="font-semibold">{formatMoney(totalPrice)}</span>
          </div>

          <CartCheckoutButton />
        </div>
      </div>
    </>
  )
}
