import React from 'react'
import { removeFalsyEntries } from './transformData'
import { SelectedService } from 'domain/orderItem/types'

export function checkIsLabelInValue(data: unknown) {
  return typeof data === 'object' &&
    Object.keys(data as object).includes('label') &&
    Object.keys(data as object).includes('value')
}
export function checkForFilters(filters: Record<any, any> | undefined) {
  if (filters) {
    const normalizedFilters = Object.values(removeFalsyEntries(filters))

    return normalizedFilters.length > 0
  }

  return false
}

export const checkIfObjectHasAllValues = (data: any) => {
  if (!data) return false

  return (
    Object.values(data).filter(
      value => value === undefined || value === null || (Array.isArray(value) && value.length === 0)
    ).length === 0
  )
}

export const validateFullName = (name: string | undefined) => {
  if (!name) return false
  const words = name.split(' ').filter(word => word !== '' && word.length >= 2)
  return words.length >= 2
}

export const validateMinLength = (value: string | undefined, minLength: number) => {
  if (!value) return false
  return value.length >= minLength
}

export const validateTituloEleitor = (titulo: string) => {
  if (!titulo) return false

  const tituloClean = titulo.replace(/\./g, '')
  const exp = /\d{4}\.\d{4}\.\d{4}/
  const expClean = /\d{4}\d{4}\d{4}/

  if (!exp.test(tituloClean) && !expClean.test(tituloClean)) {
    return false
  }

  const tam = tituloClean.length
  let dig

  try {
    dig = createTituloAtual(tituloClean)
  } catch (e) {
    return false
  }

  const digitos = tituloClean.substr(tam - 2, 2)
  return digitos === dig
}

function createTituloAtual(titulo: string) {
  let dig1 = 0
  let dig2 = 0

  const tit = titulo
  const tam = tit.length
  const estado = tit.substr(tam - 4, 2)

  titulo = tit.substr(0, tam - 2)
  titulo = '000000000000' + titulo
  titulo = titulo.substr(titulo.length - 11, titulo.length - 1)

  const exce = estado === '01' || estado === '02'

  dig1 =
    (titulo.charCodeAt(0) - 48) * 2 +
    (titulo.charCodeAt(1) - 48) * 9 +
    (titulo.charCodeAt(2) - 48) * 8 +
    (titulo.charCodeAt(3) - 48) * 7 +
    (titulo.charCodeAt(4) - 48) * 6 +
    (titulo.charCodeAt(5) - 48) * 5 +
    (titulo.charCodeAt(6) - 48) * 4 +
    (titulo.charCodeAt(7) - 48) * 3 +
    (titulo.charCodeAt(8) - 48) * 2

  let resto = dig1 % 11

  if (resto === 0) {
    dig1 = exce ? 1 : 0
  } else {
    dig1 = resto === 1 ? 0 : 11 - resto
  }

  dig2 = (titulo.charCodeAt(9) - 48) * 4 + (titulo.charCodeAt(10) - 48) * 3 + dig1 * 2
  resto = dig2 % 11

  if (resto === 0) {
    dig2 = exce ? 1 : 0
  } else {
    dig2 = resto === 1 ? 0 : 11 - resto
  }

  return dig1.toString() + dig2.toString()
}

export function validatePlacaVeiculo(placa: string) {
  const regexPlaca = /^[a-zA-Z]{3}[0-9]{4}$/
  const regexPlacaMercosulCarro = /^[a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}[0-9]{2}$/
  const regexPlacaMercosulMoto = /^[a-zA-Z]{3}[0-9]{2}[a-zA-Z]{1}[0-9]{1}$/

  if (regexPlaca.test(placa)) {
    return true
  }

  if (regexPlacaMercosulCarro.test(placa)) {
    return true
  }

  if (regexPlacaMercosulMoto.test(placa)) {
    return true
  }

  return false
}

export function onlyNumbersKeyPress(e: React.KeyboardEvent<any>) {
  const ctrlKeys = ['C', 'V', 'X']
  const validKeys = ['Backspace', 'Delete', 'Enter', 'End', 'Esc', 'Home']

  const isValidKey =
    validKeys.includes(e.key) || (e.ctrlKey && !ctrlKeys.includes(e.key)) || /[0-9]/g.test(e.key)

  if (!isValidKey) {
    e.preventDefault()
  }
}

export const checkParams = (service: Pick<SelectedService, 'categoryId' | 'id'>, data: any) => {
  if (!data || !service.id || !service.categoryId) {
    return false
  }

  const fieldsWithNoValue = Object.values(data).filter(
    value => value === undefined || value === null || (Array.isArray(value) && value.length === 0)
  )

  return fieldsWithNoValue.length === 0
}

export const getHasValidDate = (itemDate: string) => {
  const currentDate = new Date()
  const validDate = new Date(itemDate)

  return validDate < currentDate
}
