import { useQuery } from 'react-query'
import { getUFInstances } from './request'
import { SelectedService } from 'domain/createOrder/types'
import { createUseLoadUFInstancesKey } from './key'

interface Params {
  service: SelectedService
  itemId?: number
  onSuccess?: () => void
  enabled?: boolean
  params: { uf: string }
}

export const useLoadUFInstances = ({ service, enabled, itemId, onSuccess, params }: Params) => {
  const { uf } = params

  return useQuery(
    createUseLoadUFInstancesKey(service.id, uf, itemId),
    () => getUFInstances({ service, uf }),
    {
      enabled,
      onSuccess,
    }
  )
}
