import { useEffect } from 'react'

import { OpenAIEnrichment } from 'domain/openAI/enrich'
import { ViewOrderLayout, useGetOrderItem } from 'domain/orderItem/shared'

import ViewCertificateActions from './ViewCertificateActions'
import ViewCertificateStatus from './ViewCertificateStatus'
import ViewCertificateTabs from './ViewCertificateTabs'
import { useActiveTabState } from '../state'

export default function ViewCertificate() {
  const orderItem = useGetOrderItem()
  const [activeTab, setActiveTab] = useActiveTabState()

  useEffect(() => {
    if (orderItem.extractSummary) {
      setActiveTab('report')
    }
  }, [])

  return (
    <ViewOrderLayout
      sidebarActions={<ViewCertificateActions />}
      showSidebar={SHOW_SIDEBAR.includes(activeTab)}
    >
      <ViewCertificateStatus>
        <ViewCertificateTabs
          ocr={orderItem.ocr!}
          fileUrl={orderItem.fileURL!}
          onChange={setActiveTab as any}
          openAITab={
            <OpenAIEnrichment
              fileUrl={orderItem.fileURL!}
              ocr={orderItem.ocr!}
              serviceId={orderItem.service.id}
            />
          }
        />
      </ViewCertificateStatus>
    </ViewOrderLayout>
  )
}

const SHOW_SIDEBAR = ['original', 'transcript', 'openAI', 'report']
