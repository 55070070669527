import { useLoadMaritalStatus } from 'domain/createOrder/document/services/useLoadMaritalStatus'
import { useLoadNacionalities } from 'domain/createOrder/document/services/useLoadNacionalities'
import { useLoadUFFields } from 'domain/createOrder/document/services/useLoadUFFields'
import { getServiceConfig } from '../products'
import { SelectedLocation } from '../types'
import { LegalEntity } from 'domain/app/types'
import { SelectedService } from 'domain/createOrder/types'
import { useLoadNaturalities } from 'domain/createOrder/document/services/useLoadNaturalities'

export interface useLoadFormFieldsParams {
  selectedService: SelectedService
  selectedEntityType: LegalEntity
  selectedInstance: string
  selectedModel: string | number
  url_uf: SelectedLocation['url_uf']
  itemId?: number
}

export const useLoadFormFields = ({
  selectedEntityType,
  selectedService,
  selectedInstance,
  selectedModel,
  url_uf,
  itemId,
}: useLoadFormFieldsParams) => {
  const service = getServiceConfig(selectedService.code!)

  const final_uf = Array.isArray(url_uf) ? url_uf?.[0].value : url_uf?.value

  const { data, isLoading } = useLoadUFFields({
    service: selectedService,
    itemId,
    params: {
      entity: selectedEntityType,
      instance: selectedInstance,
      model: selectedModel,
      uf: final_uf ?? '',
    },
    enabled: !!selectedEntityType && !!selectedInstance && !!selectedModel && !!final_uf,
  })

  const { data: nacionalitiesData, isLoading: nacionalitiesLoading } = useLoadNacionalities({
    service: selectedService,
    itemId,
    params: {
      entity: selectedEntityType,
      instance: selectedInstance,
      model: selectedModel,
      uf: final_uf ?? '',
    },
    enabled: !!selectedEntityType && !!selectedInstance && !!selectedModel && !!final_uf,
  })

  const { data: maritalStatusData, isLoading: maritalLoading } = useLoadMaritalStatus({
    service: selectedService,
    itemId,
    params: {
      entity: selectedEntityType,
      instance: selectedInstance,
      model: selectedModel,
      uf: final_uf ?? '',
    },
    enabled: !!selectedEntityType && !!selectedInstance && !!selectedModel && !!final_uf,
  })

  const { data: naturalitiesData, isLoading: naturalitiesLoading } = useLoadNaturalities({
    service: selectedService,
    itemId,
    params: {
      entity: selectedEntityType,
      instance: selectedInstance,
      model: selectedModel,
      uf: final_uf ?? '',
    },
    enabled: !!selectedEntityType && !!selectedInstance && !!selectedModel && !!final_uf,
  })

  const allFields = Object.values({
    ...service.formFields,
    ...service.dynamicFields,
  })

  const formFields = allFields.filter(
    f => data?.includes(f.apiName) && (f.legalEntity === selectedEntityType || !f.legalEntity)
  )

  const formFieldsWithOptions = formFields.map(field => {
    if (field.apiExtraInfo === 'nacionalidades') return { ...field, options: nacionalitiesData }

    if (field.apiExtraInfo === 'estados-civis') return { ...field, options: maritalStatusData }

    if (field.apiExtraInfo === 'naturalidades') return { ...field, options: naturalitiesData }

    return field
  })

  return {
    data: formFieldsWithOptions,
    isLoading: isLoading || nacionalitiesLoading || maritalLoading || naturalitiesLoading,
  }
}
