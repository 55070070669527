import { LegalEntity } from 'domain/app/types'
import { getService } from 'domain/servicesCBRdoc'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { ServiceCode } from 'domain/servicesCBRdoc/types'

export function getServiceConfigByApiName(serviceCode: ServiceCode, selectedLegalEntity?: LegalEntity) {
  const serviceConfig = getService(serviceCode)
  const serviceFields = { ...serviceConfig.formFields, ...serviceConfig.dynamicFields }
  const legalEntity =
    selectedLegalEntity ?? serviceConfig.legalEntity !== 'indefinido' ? serviceConfig.legalEntity : undefined

  return Object.keys(serviceFields).reduce((acc, fieldName) => {
    const field = serviceFields[fieldName as FieldName]

    if (field && (!field.legalEntity || field.legalEntity === legalEntity)) {
      acc[field.apiName] = field
    }

    return acc
  }, {} as Record<string, FieldConfig>)
}
