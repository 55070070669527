import { Certificate  } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { joinStrings } from 'utils/formatters'

type CertidaoNegativaTCUFields = Record<
  FieldName.CNPJ | FieldName.CPF | FieldName.NOME_PF | FieldName.NOME_PJ,
  FieldConfig
>

const { FORMATO } = FieldName

class CertidaoNegativaTCU extends Certificate {
  formFields: CertidaoNegativaTCUFields = {
    cnpj: Fields.cnpj,
    cpf: Fields.cpf,
    nome_pf: Fields.nome_pf,
    nome_pj: Fields.nome_pj,
  }

  constructor() {
    super({
      id: 51,
      priceByFields: [FORMATO],
      shortName: 'TCU - Certidão Negativa',
      steps: ['dados', 'entrega'],
      extractedReportSections: [
        {
          title: 'Dados da certidão',
          properties: [
            { label: 'Status da certidão', key: 'status_certidao' },
            { label: 'Data da certidão', key: 'data_emissao' },
            { label: 'Réu', key: 'nome_reus', formatter: joinStrings },
          ],
        },
        {
          title: '',
          properties: [
            { label: 'Classe / Situação', key: ['classe', 'situacao'] },
            { label: 'Órgão Julgador / Relator', key: 'orgao_julgador' },
            { label: 'Assunto', key: 'assunto' },
            { label: 'Data da distribuição', key: 'data_distribuicao' },
            { label: 'Apelante', key: 'apelantes' },
          ],
          propertiesTitleFrom: 'numero',
          fromArray: 'processos'
        }
      ],
    })
  }
}

const service = new CertidaoNegativaTCU().initialize()

export default service
