import { useQuery } from 'react-query'
import { getUFEntities } from './request'
import { SelectedService } from 'domain/createOrder/types'
import { createUseLoadUFEntitiesKey } from './key'

interface Params {
  service: SelectedService
  itemId?: number
  onSuccess?: () => void
  enabled?: boolean
  params: { uf: string; instance: string; model: string }
}

export const useLoadUFEntities = ({ service, enabled, itemId, onSuccess, params }: Params) => {
  const { uf, instance, model } = params

  return useQuery(
    createUseLoadUFEntitiesKey(service.id, uf, instance, model, itemId),
    () => getUFEntities({ service, uf, instance, model }),
    {
      enabled,
      onSuccess,
    }
  )
}
