import { useMemo } from 'react'
import { CreateOrderLegalEntityTabs, useCreateOrderState } from 'domain/createOrder/document'
import { ResearchFlow } from 'domain/createOrder/researches'
import { CNPJAndNameFields, NameField, NumberField, RadioGroupField } from 'domain/servicesCBRdoc/fields'
import { CPFInput } from 'ui'

import config from '../pesquisaProcessosJudiciaisConfig'

export default function CreateOrderPesquisaProcessosJudiciais() {
  return (
    <ResearchFlow renderFormItem={(itemId: number) => <PesquisaProcessosJudiciaisForm itemId={itemId} />} />
  )
}

function PesquisaProcessosJudiciaisForm({ itemId }: { itemId: number }) {
  const { formData } = useCreateOrderState()
  const selected = useMemo(() => {
    if (formData[itemId]?.tipo) {
      return formData[itemId].tipo
    }
    return 'tipo-pessoa'
  }, [itemId, formData])
  return (
    <>
      <RadioGroupField itemId={itemId} field={tipo} value={[itemId, 'tipo-pessoa']} />
      {selected === 'tipo-pessoa' && (
        <CreateOrderLegalEntityTabs
          itemId={itemId}
          fisica={
            <>
              <CPFInput itemId={itemId} autoFocus={itemId === 0} />
              <NameField itemId={itemId} field={nome_pf} />
            </>
          }
          juridica={<CNPJAndNameFields itemId={itemId} companyField={nome_pj} />}
        />
      )}
      {selected === 'num-processo' && (
        <NumberField field={numero_processo} itemId={itemId} required />
      )}
    </>
  )
}

const { nome_pf, nome_pj, tipo, numero_processo } = config.formFields
