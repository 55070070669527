import { useSetDynamicFields } from 'domain/createOrder/kit/state'
import { useLoadFormFields, useLoadFormFieldsParams } from 'domain/servicesCBRdoc/services/useLoadFormFields'

type useLoadKitFieldsParams = Omit<useLoadFormFieldsParams, 'itemId'> & {
  itemId: number
}

export const useLoadKitFields = ({
  selectedEntityType,
  selectedInstance,
  selectedModel,
  selectedService,
  url_uf,
  itemId,
}: useLoadKitFieldsParams) => {
  const setDynamicFields = useSetDynamicFields(itemId)

  const { data } = useLoadFormFields({
    selectedService,
    selectedEntityType,
    selectedInstance,
    selectedModel,
    url_uf,
    itemId,
  })

  const fields = data.map(field => ({ ...field, apiExtraInfo: undefined }))

  setDynamicFields(fields)
}
