import { CertificateFlow } from 'domain/createOrder/certificates'
import CertidaoJusticaEstadualForm from './CertidaoJusticaEstadualForm'
import { SelectInstanceStep } from './SelectInstanceStep'
import { SelectModelStep } from './SelectModelStep'
import { SelectEntityStep } from './SelectEntityStep'

export default function CreateOrderCertidaoJusticaEstadual() {
  return (
    <CertificateFlow
      title="Preencha os dados de sua certidão"
      header={<br />}
      renderFormItem={(itemId: number) => <CertidaoJusticaEstadualForm itemId={itemId} />}
      instanceStep={<SelectInstanceStep />}
      modelStep={<SelectModelStep />}
      entityStep={<SelectEntityStep />}
      noRenderOrderName
    />
  )
}
