import { CertificateCode, ResearchCode, ServiceCode } from '../types'
import ViewPesquisaBens from './researches/pesquisaBens/components/ViewPesquisaBens'
import ViewPesquisaDivorcio from './researches/pesquisaDivorcio/components/ViewPesquisaDivorcio'
import ViewPesquisaEmpresaCartoriosPJ from './researches/pesquisaEmpresaCartoriosPJ/components/ViewPesquisaEmpresaCartoriosPJ'
import ViewPesquisaEscritura from './researches/pesquisaEscritura/components/ViewPesquisaEscritura'
import ViewPesquisaInventario from './researches/pesquisaInventario/components/ViewPesquisaInventario'
import ViewPesquisaJuntaComercial from './researches/pesquisaJuntaComercial/components/ViewPesquisaJuntaComercial'
import ViewPesquisaProcessosJudiciais from './researches/pesquisaProcessosJudiciais/components/ViewPesquisaProcessosJudiciais'
import ViewPesquisaProcuracao from './researches/pesquisaProcuracao/components/ViewPesquisaProcuracao'
import ViewPesquisaProtesto from './researches/pesquisaProtesto/components/ViewPesquisaProtesto'
import ViewPesquisaPropriedadeAeronave from './researches/pesquisaPropriedadeAeronave/components/ViewPesquisaPropriedadeAeronave'
import ViewPesquisaObito from './researches/pesquisaObito/components/ViewPesquisaObito'
import ViewCertidaoJusticaEstadual from './certificates/certidaoJusticaEstadual/components/ViewCertidaoJusticaEstadual'

const productsViews: Partial<Record<ServiceCode, any>> = {
  [ResearchCode.PESQUISA_BENS]: ViewPesquisaBens,
  [ResearchCode.PESQUISA_DIVORCIO]: ViewPesquisaDivorcio,
  [ResearchCode.PESQUISA_EMPRESA_CARTORIOS_PJ]: ViewPesquisaEmpresaCartoriosPJ,
  [ResearchCode.PESQUISA_ESCRITURA]: ViewPesquisaEscritura,
  [ResearchCode.PESQUISA_INVENTARIO]: ViewPesquisaInventario,
  [ResearchCode.PESQUISA_JUNTA_COMERCIAL]: ViewPesquisaJuntaComercial,
  [ResearchCode.PESQUISA_PROCESSOS_JUDICIAIS]: ViewPesquisaProcessosJudiciais,
  [ResearchCode.PESQUISA_PROCURACAO]: ViewPesquisaProcuracao,
  [ResearchCode.PESQUISA_PROTESTO]: ViewPesquisaProtesto,
  [ResearchCode.PESQUISA_PROPRIEDADE_AERONAVE]: ViewPesquisaPropriedadeAeronave,
  [ResearchCode.PESQUISA_OBITO]: ViewPesquisaObito,
  [CertificateCode.CERTIDAO_JUSTICA_ESTADUAL]: ViewCertidaoJusticaEstadual,
}

export default productsViews
