import React, { useState } from 'react'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { IconName } from 'ui'
import { RoutePaths, Routes } from 'app/routes'
import { OrderItem } from 'domain/orderItem/types'
import { OrderStatuses, StatusCode } from 'domain/orders/types'
import { ServiceType } from 'domain/servicesCBRdoc/types'
import { GoBackButton, PageLayout } from 'layouts'
import { displayDate } from 'utils/dateTime'
import { useRedoOrder } from 'domain/createOrder/redo'
import { useGetOrderItem } from '../state/viewOrderItem'
import { Dropdown, Button } from 'antd'
import { CreateRecurrence } from 'domain/recurrences'
import { ActionsBar, Icon, Step, StepStatus, ErrorBoundary } from 'ui'
import Breadcrumb from 'ui/Breadcrumb/Breadcrumb'
import ViewOrderStatus from './status/ViewOrderStatus'
import ViewOrderSidebar from './ViewOrderSidebar'
import OrderItemsNavigation from './OrderItemsNavigation'
import ShareOrderModal from './share/ShareOrderModal'
import ReportProblemModal from './error/ReportProblemModal'
import { MenuProps } from 'antd/lib/menu'

export type ViewOrderExtraActions = {
  excel?: any
}

export interface ViewOrderLayoutProps {
  icon?: IconName
  children?: ReactNode
  showSidebar?: boolean
  sidebarActions?: ReactNode
}

export default function ViewOrderLayout({
  children,
  sidebarActions,
  showSidebar = true,
}: ViewOrderLayoutProps) {
  const orderItem = useGetOrderItem()
  const addToCart = useRedoOrder()

  const [isShareModalOpen, setIsShareModalOpen] = useState(false)
  const [isReportModalVisible, setIsReportModalVisible] = useState(false)
  const [isRecurrenceModalOpen, setIsRecurrenceModalOpen] = useState(false)

  const breadcrumbItems = React.useMemo(
    () => [
      {
        title: (
          <Link className="text-secondary-500" to={RoutePaths.ORDERS}>
            Meus pedidos
          </Link>
        ),
      },
      {
        title: (
          <Link className="text-secondary-500" to={Routes.Order(orderItem.order.id)}>
            Nº do Pedido: {orderItem.order.orderNumber}
          </Link>
        ),
      },
      {
        title: <p className="font-bold">{`Item: #${orderItem.orderItemNumber}`}</p>,
      },
    ],
    [orderItem]
  )
  
  const moreOptionsButtonItems = React.useMemo(() => {
    const items: MenuProps['items'] = [
      {
        disabled: !orderItem?.fileURL,
        key: '0',
        icon: (
          <Icon name="share" color={orderItem?.fileURL ? '#527eed' : '#818aa5'} className="text-base mr-2" />
        ),
        label: 'Compartilhar',
        onClick: () => setIsShareModalOpen(true),
      },
      {
        disabled: !orderItem?.fileURL,
        key: '1',
        icon: (
          <Icon name="print" color={orderItem?.fileURL ? '#527eed' : '#818aa5'} className="text-base mr-2" />
        ),
        label: 'Imprimir',
        onClick: () => window.open(orderItem?.fileURL ?? ''),
      },
      {
        key: '2',
        icon: <Icon name="redo-alt" color="#527eed" className="text-base mr-2" />,
        label: 'Refazer o pedido',
        onClick: () => addToCart(orderItem),
      },
      {
        key: '3',
        icon: <Icon name="alert-alt" color="#527eed" className="text-base mr-2" />,
        label: 'Reportar o problema',
        onClick: () => setIsReportModalVisible(true),
      }]

    if (sidebarActions) {
      items.push({
        key: '4',
        label: sidebarActions,
      })
    }

    return items
  }, [orderItem, sidebarActions])

  return (
    <PageLayout
      alwaysShowExtra
      pageTitle={
        <span className="flex flex-row gap-1 items-center">
          <GoBackButton hideLabel className="pl-0" />
          <Breadcrumb items={breadcrumbItems} className="items-center font-normal" />
        </span>
      }
      subTitle={
        <ActionsBar className="mt-4 border-b-0" customPadding="p-0" customBackgroundColor="#f9f9f9">
          <OrderItemsNavigation navigation={orderItem.navigation} />
          <span className="font-bold text-lg overflow-hidden truncate flex-1">{orderItem.name}</span>

          <Button
            type="link"
            icon={<Icon name="repeat-alt" />}
            onClick={() => setIsRecurrenceModalOpen(isOpen => !isOpen)}
            className="flex text-primary border bg-white text-base border-primary"
          >
            Criar recorrência
          </Button>
          <Dropdown
            className="text-primary [&>button]:text-primary"
            menu={{ items: moreOptionsButtonItems }}
            dropdownRender={menu => <div className="dropdown-options-primary">{menu}</div>}
          >
            <Button
              type="link"
              icon={<Icon name="more-vertical-alt" className="m-0 ml-2" />}
              className={`
                border bg-white flex border-secondary-300 text-secondary-300
                flex-row-reverse text-base justify-between [&_.ant-btn-icon]:mr-0
              `}
            >
              Mais opções
            </Button>
          </Dropdown>
        </ActionsBar>
      }
      sidebar={showSidebar && <ViewOrderSidebar />}
    >
      <ErrorBoundary>
        <ViewOrderStatus>
          <div className="flex-1 overflow-y-hidden overflow-x-auto">{children}</div>
        </ViewOrderStatus>
      </ErrorBoundary>

      <CreateRecurrence
        openModal={isRecurrenceModalOpen}
        onSuccess={() => setIsRecurrenceModalOpen(false)}
        onCloseModal={() => setIsRecurrenceModalOpen(false)}
        items={[orderItem]}
        kind="order-items"
      />

      <ShareOrderModal
        open={isShareModalOpen}
        orderId={orderItem.id}
        onClose={() => setIsShareModalOpen(false)}
      />

      <ReportProblemModal
        open={isReportModalVisible}
        orderId={orderItem.id}
        onRequestClose={() => setIsReportModalVisible(false)}
      />
    </PageLayout>
  )
}

const insertIf = (condition: any, ...elements: any): Step[] => (condition ? elements : [])

export const getOrderSteps = (order: OrderItem): Step[] => {
  let stepsStatuses: Record<string, StepStatus | null> = {
    recebido: 'pending',
    processando: 'pending',
    emitido: order.service.type === ServiceType.RESEARCH ? null : 'pending',
    enviado: 'pending',
    naoEncontrado: null,
    cancelado: null,
    reembolsado: null,
    aguardandoResposta: null,
  }
  switch (order.status) {
    case StatusCode.PROCESSANDO:
      stepsStatuses = { ...stepsStatuses, recebido: 'done', processando: 'current' }
      break
    case StatusCode.FINALIZADO:
      stepsStatuses = {
        ...stepsStatuses,
        recebido: 'done',
        processando: 'done',
        emitido: order.service.type === ServiceType.RESEARCH ? null : 'done',
        enviado: 'done',
      }
      break
    case StatusCode.ACAO_NECESSARIA:
      stepsStatuses = {
        ...stepsStatuses,
        recebido: 'done',
        processando: 'done',
        emitido: null,
        acaoNecessaria: 'failed',
      }
      break
    case StatusCode.REEMBOLSADO:
      stepsStatuses = {
        ...stepsStatuses,
        recebido: 'done',
        processando: 'done',
        emitido: null,
        enviado: null,
        reembolsado: 'done',
      }
      break
    case StatusCode.AGUARDANDO_RESPOSTA:
      stepsStatuses = {
        ...stepsStatuses,
        recebido: 'done',
        processando: 'done',
        emitido: null,
        enviado: null,
        aguardandoResposta: 'current',
      }
      break
  }

  return [
    ...insertIf(stepsStatuses.recebido !== null, {
      title: 'Pedido recebido',
      status: stepsStatuses.recebido,
      date: order.createdAtDisplay,
    }),
    ...insertIf(stepsStatuses.processando !== null, {
      title: 'Processando',
      status: stepsStatuses.processando,
    }),
    ...insertIf(stepsStatuses.emitido !== null, {
      title: 'Emitido',
      status: stepsStatuses.emitido,
    }),
    ...insertIf(stepsStatuses.acaoNecessaria !== null, {
      title: OrderStatuses[StatusCode.ACAO_NECESSARIA].name,
      status: stepsStatuses.acaoNecessaria,
    }),
    ...insertIf(stepsStatuses.enviado !== null, {
      title: 'Enviado',
      status: stepsStatuses.enviado,
    }),
    ...insertIf(stepsStatuses.cancelado !== null, {
      title: 'Cancelado',
      status: stepsStatuses.cancelado,
    }),
    ...insertIf(stepsStatuses.reembolsado !== null, {
      title: 'Reembolsado',
      status: stepsStatuses.reembolsado,
    }),
    ...insertIf(stepsStatuses.aguardandoResposta !== null, {
      title: 'Aguardando resposta',
      status: stepsStatuses.aguardandoResposta,
    }),
    {
      title: 'Finalizado',
      status: order.finishedAt ? 'done' : 'pending',
      date: order.finishedAt ? displayDate(order.finishedAt) : '',
    },
  ]
}
