import React, { useReducer } from 'react'
import { Button } from 'antd'

import { Modal } from 'ui'
import useRequestCancellation from '../../services/useRequestCancellation'
import { useViewOrderItem } from '../../state/viewOrderItem'

export default function RequestCancellation() {
  const [showModal, toggleModal] = useReducer(prev => !prev, false)
  const [{ id }, refreshOrderItem] = useViewOrderItem()

  const { requestCancellation, loading } = useRequestCancellation(id, {
    onSuccess: () => {
      refreshOrderItem()
      toggleModal()
    },
  })

  return (
    <>
      <Button className="rounded-md w-full" type="primary" ghost onClick={toggleModal} loading={loading}>
        Cancelar Pedido
      </Button>

      <Modal open={showModal} title="Cancelamento" onCancel={toggleModal}>
        <div className="border-b border-gray-300 pt-1 pb-6">
          <p>Confirma o cancelamento do pedido?</p>
        </div>

        <Button type="primary" onClick={requestCancellation} loading={loading} block>
          {loading ? 'Confirmando...' : 'Confirmar cancelamento'}
        </Button>
      </Modal>
    </>
  )
}
