import { Form, Spin } from 'antd'

import { useGetLegalEntity, useServiceForm } from 'domain/createOrder/kit'

import { KitItem } from 'domain/kits/types'

import AlertDocumentsRequired from './AlertDocumentsRequired'
import { useLoadKitFields } from 'domain/createOrder/kit/services/useLoadKitFields'
import { Fields } from 'domain/servicesCBRdoc'

import { useLoadUFInstances } from 'domain/createOrder/document/services/useLoadUFInstances'
import { RadioGroupField } from 'domain/servicesCBRdoc/fields'
import { useLoadUFModels } from 'domain/createOrder/document/services/useLoadUFModels'

interface Props {
  kitItem: KitItem
}

export default function KitOrderCertidaoJusticaEstadual({ kitItem }: Props) {
  const { id, service } = kitItem
  const legalEntity = useGetLegalEntity()
  const [{ url_uf, instancia, modelo }, updateForm] = useServiceForm(id)
  const form = Form.useFormInstance()
  const federativeUnit = url_uf?.value as string

  const {
    data: instancesData,
    isLoading: instancesLoading,
    isError: instancesError,
  } = useLoadUFInstances({
    service,
    params: { uf: federativeUnit },
    enabled: !!federativeUnit,
  })

  const {
    data: modelsData,
    isLoading: modelsLoading,
    isError: modelsError,
  } = useLoadUFModels({
    service: kitItem.service,
    params: { uf: federativeUnit, instance: instancia },
    enabled: !!federativeUnit && !!instancia,
  })

  useLoadKitFields({
    selectedEntityType: legalEntity,
    selectedInstance: instancia,
    itemId: id,
    selectedModel: modelo,
    selectedService: kitItem.service,
    url_uf,
  })

  return (
    <>
      <Spin spinning={instancesLoading}>
        <RadioGroupField
          itemId={id}
          field={Fields.instancia}
          onChange={instancia => {
            form.setFieldValue([id, 'instancia'], instancia)
            form.setFieldValue([id, 'modelo'], null)
            updateForm({ modelo: null, instancia })
          }}
          options={instancesData}
          hasError={instancesError}
          required
        />
      </Spin>

      <Spin spinning={modelsLoading}>
        <RadioGroupField
          itemId={id}
          field={Fields.modelo}
          onChange={modelo => {
            form.setFieldValue([id, 'modelo'], modelo)
            updateForm({ modelo })
          }}
          options={modelsData}
          hasError={modelsError}
          vertical
          required
        />
      </Spin>

      <AlertDocumentsRequired entityType={legalEntity} federativeUnit={federativeUnit} instance={instancia} />
    </>
  )
}
