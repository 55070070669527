import { LabelInValue, LegalEntity, TempFile } from 'domain/app/types'
import { PaymentMethod } from 'domain/checkout/types'
import { OrderItem, OrderItemDetails, ResearchResult } from 'domain/orderItem/types'
import { SelectedLocation, ServiceAPI, ServiceCode, ServiceType } from 'domain/servicesCBRdoc/types'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { ShoppingCartOrder } from 'domain/shoppingCart/types'
import { OrderItemAPI } from 'domain/orderItem/typesAPI'

export type ExtraService = Record<string, { quantidade: number; valor_total: number } | any>

export type ShoppingStep =
  | 'categoria'
  | 'dados'
  | 'entrega'
  | 'localizacao'
  | 'upload'
  | 'assinantes'
  | 'instancia'
  | 'modelo'
  | 'entidade'

export enum AIShoppingStep {
  MODELO = 'modelo',
  DADOS = 'dados',
  ARQUIVOS = 'arquivos',
}

export type CreateOrderForm = Partial<Record<FieldName | 'assinantes' | number, any>>

export type TransformOrderFn = (orders: ShoppingCartOrder[]) => ShoppingCartOrder[]

export type MissingFields = {
  orderItem: OrderItem
  result: ResearchResult
  fields: FieldConfig[]
}

export type CreateStatus = 'process' | 'review' | 'success' | 'failed'

export interface PriceShippingInfo {
  price: number
  estimated_price_postpaid_customer: number
  estimated_delivery_days: number[]
}

export interface AvailableFormatPriceShippingInfo {
  prazo_entrega_estimado: string[]
  dias_adicionados_prazo: number
  preco: number
}

export type DeliveryFormat = 'combo' | 'email' | 'papel'

export type AvailableFormatsByType = {
  [key in DeliveryFormat]?: AvailableFormatPriceShippingInfo
}

export type AvailableProtestoFormat = {
  [key in DeliveryFormat]?: {
    10: AvailableFormatPriceShippingInfo
    uni: AvailableFormatPriceShippingInfo
  }
}

export interface ExtraAttributes {
  valor_adicional: number
  dias_adicionados_prazo: number
}

export interface AvailableExtras {
  apostilamento: ExtraAttributes
  aviso_recebimento: ExtraAttributes
  envio_internacional: ExtraAttributes
  inteiro_teor: {
    transcricaodigital: ExtraAttributes
    transcricaodigitalreprografica: ExtraAttributes
    reprografica: ExtraAttributes
  }
  pasta_protecao: ExtraAttributes
  previa_digitalizada: ExtraAttributes
  reconhecimento_firma: ExtraAttributes
  traducao_juramentada: {
    idiomas: {
      alemao: ExtraAttributes
      espanhol: ExtraAttributes
      frances: ExtraAttributes
      ingles: ExtraAttributes
      italiano: ExtraAttributes
    }
  }
  qtde_xerox_simples: ExtraAttributes
  qtde_xerox_autenticado: ExtraAttributes
}

export type ApostilamentoTraducaoType = 'certidao' | 'traducao' | 'certidaotraducao'

export interface Extras {
  qtde_xerox_simples?: number
  qtde_xerox_autenticado?: number
  previa_digitalizada?: boolean
  reconhecimento_firma?: boolean
  apostilamento?: boolean
  pasta_protecao?: boolean
  traducao_juramentada?: boolean
  idioma_traducao_juramentada?: 'italiano' | 'espanhol' | 'frances' | 'ingles' | 'alemao'
  tipo_apostilamento_com_traducao?: ApostilamentoTraducaoType
  aviso_recebimento?: boolean
  inteiro_teor?: boolean
  tipo_inteiro_teor?: InteiroTeorType
}

export type EstimatedDeliveryTime = number[] | number | null
export type EstimatedDeliveryDate = string[] | string | null

export interface OrderDetails {
  totalValue: number | null
  estimatedDeliveryTime: EstimatedDeliveryTime
  extras?: Record<string, { quantidade: number; valor_total: number } | any>
}

export type PostData = Partial<Record<string, any>>

export type CreateOrderFormFieldName =
  | FieldName
  | `${number}[${FieldName}]`
  | 'automaticResults'
  | 'origin'
  | 'pesquisa_origem_id'

export type ResearchTime = 5 | 10

export type FormItems = Record<string | number, CreateOrderFormItem>

export type SelectedService = {
  id: number
  categoryId: number
  code?: ServiceCode
  type?: ServiceType
  name: string
  shortName?: string
}

export type SelectedServiceAPI = Pick<
  ServiceAPI,
  'id' | 'code' | 'name' | 'category1_id' | 'category2_id' | 'spreadsheet_purchase_available' | 'type'
>

export interface CreateOrderFormItem {
  id: number
  itemOrder: number
  availableTypes?: string[]
  countItems?: number
  dontKnowBookPage?: boolean
  dynamicFields?: FieldName[]
  orderDetails: OrderDetails
  selectedEntityType?: LegalEntity
  selectedRegion?: string
  selectedResearchTime?: ResearchTime
  selectedModel?: string | number
  selectedInstance?: string | number
  selectedType?: string | LabelInValue
}

export type Origin = {
  description: string
  researchId?: number
}

export type CreateOrderFormData = Record<any, any>

export interface CreateOrderState extends Omit<CreateOrderFormItem, 'id' | 'itemOrder'> {
  allNotariesSelected?: boolean
  availableFormats: DeliveryFormat[]
  availableResearchTimes: ResearchTime[]
  availableExtras?: AvailableExtras
  availableFormatsInfoLoading?: boolean
  cartItemId: string
  createStatus: CreateStatus
  files?: TempFile[]
  formData: CreateOrderFormData
  formItems: FormItems
  kitItemId?: number
  origin: Origin
  multipleItemsLoadingPrice?: boolean
  selectedComarca?: string
  selectedExtras?: Extras
  selectedFormat: DeliveryFormat | undefined
  selectedInstance?: string
  selectedLocation: SelectedLocation
  selectedService: SelectedService
  submitData?: Record<any, any>
}

export type GetRequiredFieldsByTypeParams = {
  serviceId?: number
  categoryId?: number
  federativeUnit?: string
}

export type GetServiceInformationFn = (
  data: CreateOrderParamsFields,
  itemId?: string | number
) => OrderItemDetails | null

export type CreateOrderFromResearchResult = Pick<CreateOrderState, 'formData'>

export type GenerateOrderFromResearchResultFn = (
  result: ResearchResult,
  orderItem: OrderItemAPI
) => Partial<CreateOrderState>

export type SimilarOrder = {
  cartItemId?: string
  checkedValues?: string
  formItemId?: number
  id?: number
  isRepeatedOnForm?: boolean
  kitItemId: number
  orderName: string
}

export interface SameLocationSimilarOrder extends SimilarOrder {
  itemId: string
  register?: string
}

export type WaitingInvoicePayment = {
  bankSlipBarcode: string
  bankSlipURL: string
  bonusAmount?: number
  paymentMethod: PaymentMethod
  pixQRCode: string
  pixKey: string
}

export type WaitingInvoicePaymentAPI = {
  id: number
  customer_id: number
  invoice_id: number
  user_id: number
  order_id: number
  operation: 'C'
  pix_hash: string
  pix_qr_code: string
  placed_time: string
  approved_at: string | null
  amount: number
  payment_method: PaymentMethod
  backoffice_id: number
  bank_slip_url: string
  bank_slip_barcode: string
  bonus_amount: number
}

export type PlaceOrderResponseAPI = {
  id: number
  backoffice_id: number
  // Pedido via planilha
  purchase?: {
    id: number
    backoffice_id: number
  }
  waiting_invoice_payment: WaitingInvoicePaymentAPI | null
}

export type PlaceOrderSpreadSheetAPI = {
  id: number
  backoffice_id: number
  purchase?: {
    id: number
    backoffice_id: number
  }
  results: any
  waitingInvoicePayment: WaitingInvoicePaymentAPI | null
}

export type SpreadsheetError = {
  validate: string[]
  message: string[]
}

export type SpreadsheetValidateData = {
  totalOrders?: number
  totalOrderItems?: number
  totalCost?: number
  needsAddress?: boolean
  lackingCreditsAmount?: number
}

export type InteiroTeorType = 'transcricaodigital' | 'reprografica' | 'transcricaodigitalreprografica'

export type CreateOrderParamsFields = Pick<
  CreateOrderState,
  | 'allNotariesSelected'
  | 'dontKnowBookPage'
  | 'dynamicFields'
  | 'formData'
  | 'formItems'
  | 'selectedComarca'
  | 'selectedEntityType'
  | 'selectedFormat'
  | 'selectedInstance'
  | 'selectedLocation'
  | 'selectedModel'
  | 'selectedRegion'
  | 'selectedResearchTime'
  | 'selectedService'
  | 'selectedType'
>

export interface PurchaseWithErrorState {
  message: string
  requestID?: string
}

export interface PurchaseWithSuccessState {
  backofficeId?: number | string
  orderId?: number
}
