import { Form, FormItemProps, Radio, RadioChangeEvent, Space } from 'antd'

import { FieldConfig } from 'domain/servicesCBRdoc/fields/types'
import { getFieldName } from 'domain/createOrder/shared'
import { requiredRule } from 'app/config/validationRules'
import { FormLabel } from 'ui'

export interface RadioGroupFieldProps extends Omit<FormItemProps, 'label'> {
  field: FieldConfig
  options?: any
  itemId?: number | string
  required?: boolean
  vertical?: boolean
  autoFocus?: boolean
  value?: any
  onChange?: (selected: any) => void
  hasError?: boolean
  labelInValue?: boolean
}

export default function RadioGroupField({
  field,
  itemId,
  options = field.options,
  required = !field.optional,
  vertical = field.vertical,
  autoFocus,
  onChange,
  value,
  hasError,
  help,
  labelInValue,
  ...props
}: RadioGroupFieldProps) {
  const name = getFieldName(field.name, itemId)

  const handleChange = (e: RadioChangeEvent) => {
    if (onChange) {
      const selected = e.target.value

      if (labelInValue) {
        onChange(options.find((o: any) => o[field.apiOptionsValueKey ?? 'url'] === selected))
      } else {
        onChange(selected)
      }
    }
  }

  if (!options) return null

  return (
    <Form.Item
      name={name}
      label={
        field.label ? (
          <FormLabel label={field.label} description={field.labelDescription} required={required} />
        ) : undefined
      }
      rules={required ? requiredRule : undefined}
      help={hasError ? 'Erro ao carregar os dados.' : help}
      {...props}
    >
      <Radio.Group value={value} onChange={handleChange} className="w-full" data-hj-allow>
        <Space direction={vertical || options?.length > 3 ? 'vertical' : 'horizontal'} className="w-full">
          {options?.map((option: any, index: number) => {
            const value = option[field.apiOptionsValueKey ?? 'url']
            const label = option[field.apiOptionsLabelKey ?? 'nome']
            const description = option.description

            return (
              <Radio
                key={value}
                value={value}
                autoFocus={autoFocus && index === 0}
                data-hj-allow
                className="w-full flex-1 [&_.ant-radio]:self-start [&_.ant-radio]:mt-0.5 [&:last-child(span)]:flex-grow"
              >
                {description ? <p className="text-secondary-500 font-bold">{label}</p> : label}
                {description && <p className="mb-1 text-xs text-gray-500 w-full">{description}</p>}
              </Radio>
            )
          })}
        </Space>
      </Radio.Group>
    </Form.Item>
  )
}
