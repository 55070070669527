import { CreateOrderKitItem, KitServicesForm } from 'domain/createOrder/kit/types'
import { CreateOrder, generateOrderName } from 'domain/createOrder/shared/services'
import { KitItem } from 'domain/kits/types'
import { fieldNames } from 'domain/servicesCBRdoc/fields/components/AddressFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { getValue } from 'utils/transformData'

export default class KitCreateOrder extends CreateOrder {
  private formRegistreeData
  private formServiceDetails
  private formServicesData: KitServicesForm
  private kitItem
  private kitLegalEntity

  constructor(
    { requiredDynamicFields, formCommonData, formServicesData, kitLegalEntity }: CreateOrderKitItem,
    kitItem: KitItem
  ) {
    super(kitItem.service)

    this.dynamicFields = requiredDynamicFields
    this.kitItem = kitItem
    this.kitLegalEntity = kitLegalEntity

    this.formServiceDetails = formCommonData?.[kitItem.id] ?? {}
    this.formServicesData = formServicesData ?? {}
    this.formRegistreeData = formCommonData?.registree ?? {}
  }

  private getLegalEntity = () => {
    if (this.formServicesData.tipo_pessoa) {
      return this.formServicesData.tipo_pessoa
    }

    if (this.service.legalEntity) {
      if (this.service.legalEntity !== 'indefinido') {
        return this.service.legalEntity
      }

      return this.kitLegalEntity !== 'indefinido' ? this.kitLegalEntity : 'fisica'
    }

    return undefined
  }

  private extractRegistreeServiceFields = (allData: any) => {
    const validFields: Partial<Record<FieldName, any>> = {}
    const fieldsWithEquivalentName = this.service.allFields.filter(f => f.kitFieldEquivalent)
    const hasFullAddress = !!this.service.allFields.find(
      f => f.name === FieldName.REGISTRADO_ENDERECO_COMPLETO
    )

    Object.entries(allData).forEach(([fieldName, value]) => {
      if (value === undefined) {
        return
      }

      let field: FieldConfig | undefined

      if (fieldsWithEquivalentName.length > 0) {
        field = fieldsWithEquivalentName.find(f => f.kitFieldEquivalent === fieldName)
      }

      if (!field) {
        field = this.service.allFields.find(f => f.name === fieldName)
      }

      if (!field && hasFullAddress && Object.values(fieldNames).includes(fieldName as any)) {
        ;(validFields as any)[fieldName as any] = value
      }

      if (field) {
        validFields[field.name] = value
      }
    })

    const registreeZipCode = fieldsWithEquivalentName.find(
      o => o.kitFieldEquivalent === FieldName.REGISTRADO_ENDERECO_CEP
    )

    if (registreeZipCode && !validFields[registreeZipCode.name]) {
      validFields[registreeZipCode.name] = allData['cep']
    }

    return validFields
  }

  private getRegistreeDataWithValidDynamicFields() {
    const formData: any = JSON.parse(JSON.stringify(this.formRegistreeData))

    if (this.service.checkDynamicFieldsOnKit && this.dynamicFields && this.service.dynamicFields) {
      let fieldNamesToCheck = Object.keys(formData) as FieldName[]

      const hasRegistreeFullAddressFields = this.dynamicFields.includes(
        FieldName.REGISTRADO_ENDERECO_COMPLETO
      )
      const addressFields = Object.values(fieldNames)

      if (hasRegistreeFullAddressFields) {
        fieldNamesToCheck = fieldNamesToCheck.filter(name => {
          if (addressFields.includes(name as any)) {
            return true
          }
          return false
        })
      }

      for (const name of fieldNamesToCheck) {
        if (hasRegistreeFullAddressFields && addressFields.includes(name as any)) {
          continue
        }

        const isDynamicAndIsNotRequired = !this.service.formFields[name] && !this.dynamicFields.includes(name)

        if (isDynamicAndIsNotRequired) {
          delete formData[name]
        }
      }

      return formData
    }

    return this.extractRegistreeServiceFields(formData)
  }

  private extractRegistreeData = () => {
    const formData = this.getRegistreeDataWithValidDynamicFields()

    if (this.service.legalEntity && !formData[TIPO_PESSOA]) {
      formData[TIPO_PESSOA] = this.getLegalEntity()
    }

    return formData
  }

  private extractServiceDetails = () => {
    const details: any = {}
    Object.entries(this.formServiceDetails).forEach(([fieldName, value]) => {
      const fieldConfig = this.service.getField(fieldName)

      if (fieldConfig) {
        if (!fieldConfig.labelInValue && ['radio', 'select'].includes(fieldConfig.kind!)) {
          value = getValue(value)
        }

        details[fieldName] = value
      }
    })

    return details
  }

  generate = () => {
    const formData: any = {
      ...(this.formServicesData[this.kitItem.id] ?? {}),
      ...this.extractRegistreeData(),
      ...this.extractServiceDetails(),
    }

    const createOrder = this.generateCreateOrder(formData, {
      description: `kit ${this.kitItem.kitId} item ${this.kitItem.id}`,
    })

    let orderName = generateOrderName(createOrder, 0)

    if (this.formServicesData[NOME_ITEM_PEDIDO]) {
      orderName = `${orderName} - ${this.formServicesData[NOME_ITEM_PEDIDO]}`.substring(0, 120)
    }

    if (this.service.allowMultipleItems) {
      createOrder.formData[0][NOME_ITEM_PEDIDO] = orderName
    } else {
      createOrder.formData[NOME_ITEM_PEDIDO] = orderName
    }

    createOrder.kitItemId = this.kitItem.id

    return createOrder
  }
}

const { NOME_ITEM_PEDIDO, TIPO_PESSOA } = FieldName
