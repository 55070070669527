import React from 'react'
import { Icon } from 'ui'
import { Dayjs } from 'dayjs'
import { daysOfWeek } from 'domain/app/data'
import { displayFulMonthDayYearString, displayMonthString } from 'utils/dateTime'

type Props = {
  dayOfMonth?: number
  daysOfWeek: number[]
  everyXDays: number | undefined
  frequency: string
  startsAt: Dayjs
}

function FrequencyMessage(props: Props) {
  return (
    <div className="px-3 py-3 border border-success-500 bg-success-100 flex items-center gap-2">
      <div className="grow shrink-0">
        <Icon name="calendar" className="text-green-600" size={25} />
      </div>
      <div className="shrink text-gray-700">
        <FrequencyText {...props} />
      </div>
    </div>
  )
}

function FrequencyText({ daysOfWeek, everyXDays, frequency, startsAt, dayOfMonth }: Props) {
  const initialDate = displayFulMonthDayYearString(startsAt)

  if (frequency === 'weekly') {
    return daysOfWeek.length > 0 ? (
      <WeeklyMessage initialDate={initialDate} selected={daysOfWeek} />
    ) : (
      <span>Selecione o dia da semana</span>
    )
  }

  if (frequency === 'monthly') {
    return <MonthlyMessage dayOfMonth={dayOfMonth} initialDate={initialDate} startsAt={startsAt} />
  }

  if (frequency === 'yearly') {
    return <YearlyMessage runRecurrenceDay={dayOfMonth} initialDate={initialDate} startsAt={startsAt} />
  }

  return <EveryXDaysMessage initialDate={initialDate} everyXDays={everyXDays} />
}

function WeeklyMessage({ selected, initialDate }: { selected: number[]; initialDate: string }) {
  const isoDaysOfWeek = selected.map(day => daysOfWeek[day].dayOfWeek)

  return (
    <span>
      A solicitação ocorrerá a cada <b>{isoDaysOfWeek?.join(', ')} </b>
      iniciando na primeira <b>{daysOfWeek[selected[0]].dayOfWeek} </b> a partir de <b>{initialDate}</b>
    </span>
  )
}

function MonthlyMessage({ initialDate, dayOfMonth }: Pick<Props, 'startsAt'> & { initialDate: string, dayOfMonth?: number }) {
  const specifiedRecurrenceDay = (
    dayOfMonth
      ? `a cada dia ${dayOfMonth} de cada mês`
      : 'mensalmente'
  )

  return (
    <span>
      A solicitação ocorrerá <b>{specifiedRecurrenceDay}</b>,
      de cada mês, iniciando após <b>{initialDate}</b>
    </span>
  )
}

function YearlyMessage({ initialDate, startsAt, runRecurrenceDay }: Pick<Props, 'startsAt'> & { initialDate: string, runRecurrenceDay?: number }) {
  const month = displayMonthString(startsAt)
  const specifiedRecurrenceDay = (
    runRecurrenceDay
      ? `a cada dia ${runRecurrenceDay} do mês de ${month}`
      : 'anualmente'
  )

  return (
    <span>
      A solicitação ocorrerá <b>{specifiedRecurrenceDay}</b>,
      iniciando após <b>{initialDate}</b>
    </span>
  )
}

function EveryXDaysMessage({ initialDate, everyXDays }: Pick<Props, 'everyXDays'> & { initialDate: string }) {
  return (
    <span>
      A solicitação ocorrerá
      <b>{Number(everyXDays) > 1 ? ` a cada ${everyXDays} dias` : ' diariamente'}</b>,
      iniciando em <b>{initialDate}</b>
    </span>
  )
}

export default FrequencyMessage
