import { Fields, getServiceConfig } from 'domain/servicesCBRdoc'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { CreateOrderFormItem, CreateOrderState } from '../../types'

export default function generateOrderName(
  { formData, formItems, selectedEntityType, selectedService }: CreateOrderState,
  itemId?: number,
  getValueFromForm?: (fieldName: FieldName) => any
) {
  const service = getServiceConfig(selectedService.code!)
  const {
    orderNameField,
    orderNameFieldMap,
    orderNamePrefixField,
    shortName,
    splittableField,
    orderNameByShowIf,
  } = service
  let document = ''
  let documentType = ''

  const getFieldValue = (fieldName: FieldName) => {
    if (getValueFromForm) {
      return getValueFromForm(fieldName)
    }

    if (itemId === undefined) {
      return formData[fieldName]
    }

    return (
      formItems[itemId]?.[fieldName as unknown as keyof CreateOrderFormItem] ??
      formData[itemId]?.[fieldName] ??
      formData[fieldName]
    )
  }

  const transformNameIfSplittable = (documentField: FieldConfig) => {
    if (documentField.name === splittableField && document) {
      const subItems = document.split('\n').filter(Boolean)
      documentType = documentField.orderNameText ?? documentField.label

      if (subItems.length > 1) {
        documentType = `${documentType}s`

        if (subItems.length === 2) {
          document = subItems.join(' e ')
        } else {
          document = subItems[0] + ` +${subItems.length - 1}`
        }
      }
    }
  }

  // Função para buscar campos com showIf == true
  const getShowIfFields = () => {
    return Object.values(service.formFields).filter(
      (field: FieldConfig) => field.showIf && field.showIf(formData) === true
    )
  }

  if (orderNamePrefixField) {
    const valueFromState = getFieldValue(orderNamePrefixField)
    const prefixField = valueFromState?.value
      ? valueFromState
      : { value: valueFromState, label: Fields[valueFromState as FieldName]?.label }

    const typeFieldName = prefixField.value as FieldName
    const documentField = service.getField(typeFieldName)

    if (orderNameFieldMap?.[typeFieldName]) {
      const fieldName = orderNameFieldMap[typeFieldName]
      const fieldConfig = service.getField(fieldName)

      if (fieldConfig) {
        documentType = fieldConfig.label
        document = getFieldValue(fieldName)
      }
    }

    if (!document) {
      if (documentField) {
        documentType = documentField.kind === 'name' ? '' : documentField.label
        document = getFieldValue((documentField.apiName ?? documentField.name) as FieldName) ?? ''
        transformNameIfSplittable(documentField)
      } else {
        documentType = prefixField.label
        document = getFieldValue(orderNameField ?? prefixField.value)
      }
    }
  } else if (orderNameField) {
    const field = Fields[orderNameField]
    const valueFromFieldName = getFieldValue(field.apiName as FieldName)
    const valueFromName = getFieldValue(field.name as FieldName)

    document = valueFromFieldName ?? valueFromName
    documentType = field.kind === 'name' ? '' : Fields[orderNameField].label

    transformNameIfSplittable(field)
  } else if (orderNameByShowIf) {
    const showIfFields = getShowIfFields()
    for (const field of showIfFields) {
      const value = getFieldValue(field.name as FieldName)
      if (value) {
        document = value
        documentType = field.label || ''
        break
      }
    }
  } else {
    const entity =
      itemId !== undefined ? formItems[itemId]?.selectedEntityType ?? selectedEntityType : selectedEntityType
    const isPJ = entity === 'juridica'
    documentType = isPJ ? 'CNPJ' : 'CPF'
    document = isPJ ? getFieldValue(FieldName.CNPJ) : getFieldValue(FieldName.CPF)
  }

  if (!document) {
    document = getFieldValue(FieldName.CNPJ) ?? getFieldValue(FieldName.CPF)
  }

  const info = document ? `${documentType || ''} ${document}` : ''

  if (info) {
    return `${shortName} - ${info}`
  }

  return shortName
}
