import React from 'react'

import { useCurrentCustomer } from 'domain/customers'
import { CanAutoPurchaseResearchOption } from 'domain/customers/types'
import {
  CreateOrderFlow,
  CreateOrderFlowProps,
  CreateOrderForm,
  MultipleItemsSidebar,
  useCreateOrderState,
} from 'domain/createOrder/document'
import { ResultCode } from 'domain/orders/types'
import { useServiceById } from 'domain/servicesCBRdoc'
import { Service } from 'domain/servicesCBRdoc/types'
import AutoPurchaseFromResearchResult from './AutoPurchaseFromResearchResult'

interface Props extends Omit<CreateOrderFlowProps, 'dataStep' | 'sidebar'> {
  renderFormItem: (itemId: number) => JSX.Element
  header?: React.ReactNode
}

export default function ResearchFlow(props: Props) {
  const customer = useCurrentCustomer()
  const { selectedService } = useCreateOrderState()
  const service = useServiceById(selectedService.id)
  const initialState = useResearchInitialState(service, customer.permissions.autoPurchaseFromResearch)

  if (!customer.id || !service) return null
  const hasAutoPurchaseFromResult =
    customer.permissions.autoPurchaseFromResearch &&
    (service.canAutoPurchaseFromNegativeResearchResult || service.canAutoPurchaseFromPositiveResearchResult)

  return (
    <CreateOrderFlow
      sidebar={<MultipleItemsSidebar />}
      dataStep={
        <CreateOrderForm
          sectionTitle="Dados da Pesquisa"
          initialValues={initialState}
          footer={
            hasAutoPurchaseFromResult && (
              <AutoPurchaseFromResearchResult automaticResults={initialState.automaticResults} />
            )
          }
          isResearch
          {...props}
        />
      }
    />
  )
}

const useResearchInitialState = (
  service: Service | undefined,
  customerAutoPurchase: CanAutoPurchaseResearchOption
) => {
  const { formData } = useCreateOrderState()

  if (
    !service ||
    customerAutoPurchase !== CanAutoPurchaseResearchOption.YES_DEFAULT ||
    (!service.canAutoPurchaseFromNegativeResearchResult && !service.canAutoPurchaseFromPositiveResearchResult)
  ) {
    if (formData.automaticResults && !Array.isArray(formData.automaticResults)) {
      return {
        ...formData,
        automaticResults: [],
      }
    }
    return formData
  }

  const automaticResults: any = []

  if (service.canAutoPurchaseFromPositiveResearchResult) {
    automaticResults.push(ResultCode.POSITIVE)
  }

  if (service.canAutoPurchaseFromNegativeResearchResult) {
    automaticResults.push(ResultCode.NEGATIVE)
  }

  return {
    ...formData,
    automaticResults,
  }
}
