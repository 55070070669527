import { useQuery } from 'react-query'
import { getUFModels } from './request'
import { SelectedService } from 'domain/createOrder/types'
import { createUseLoadUFModelsKey } from './key'

interface Params {
  service: SelectedService
  itemId?: number
  onSuccess?: () => void
  enabled?: boolean
  params: { uf: string; instance: string }
}

export const useLoadUFModels = ({ service, enabled, itemId, onSuccess, params }: Params) => {
  const { uf, instance } = params

  return useQuery(
    createUseLoadUFModelsKey(service.id, uf, instance, itemId),
    () => getUFModels({ service, uf, instance }),
    {
      enabled,
      onSuccess,
    }
  )
}
