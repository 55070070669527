import { OrderItem, ResearchResultsFieldName } from 'domain/orderItem/types'
import { getFieldConfigByAPIName, getServiceConfigByApiName } from 'domain/servicesCBRdoc'
import {
  RealStateSearchFor,
  SearchForLabels,
} from 'domain/servicesCBRdoc/products/certificates/certidaoImovel/types'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { displayDate } from 'utils/dateTime'
import { formatCNPJ, formatCPF } from 'utils/formatters'

export default function useExtractOrderDetails(orderItem: OrderItem) {
  const { service, submitData } = orderItem
  const fieldsConfig = getServiceConfigByApiName(service!.code, submitData.tipo_pessoa)
  const entries = Object.entries(submitData ?? {})
  const hasRegistreeAddress = entries.some(([key]) => key === FieldName.REGISTRADO_ENDERECO_COMPLETO)

  return (
    entries
      // o endereço é formatado de outra forma, então não vamos
      // renderizar nenhum item de endereço aqui
      .filter(([key, value]) => {
        if (
          value === undefined ||
          value === null ||
          key.startsWith('endereco_entrega') ||
          excludedItemKeysFromDetails.includes(key) ||
          (hasRegistreeAddress && addressKeys.includes(key))
        ) {
          return false
        }
        return true
      })
      .map(([fieldName, value]) => {
        if (value) {
          const config = fieldsConfig[fieldName] ?? getFieldConfigByAPIName(fieldName)

          if (config) {
            fieldName = config.infoLabel ?? config.label

            if (config.kind === 'date') {
              value = displayDate(value as string)
            } else if (config.kind === 'cpfOrCnpj') {
              if (config.legalEntity === 'juridica') {
                value = formatCNPJ(value as string)
              } else {
                value = formatCPF(value as string)
              }
            } else if (config.name === FieldName.BUSCA_POR) {
              value = SearchForLabels[value as RealStateSearchFor]
            } else if (config.options && typeof value === 'string') {
              const selected = config.options.find(o => o.value === value)

              if (selected) {
                value = selected.label
              }
            } else if (config.apiExtraInfo && typeof value === 'string') {
              value = value.replaceAll('-', ' ').replaceAll('_', ' ')
            }
          } else {
            if (fieldName === 'data') {
              fieldName = 'Data'
              value = displayDate(value)
            } else if (fieldName === 'data_resultado') {
              fieldName = 'Data do resultado'
              value = displayDate(value)
            } else if (LabelsWithoutFieldConfig[fieldName as ResearchResultsFieldName]) {
              fieldName = LabelsWithoutFieldConfig[fieldName as ResearchResultsFieldName]!
            }
          }
        }

        if (typeof value === 'object') {
          value = value.nome ?? value.sigla ?? value
        }

        return [fieldName, value]
      })
      .filter(([key]) => {
        const isDocumentOrCpfCnpj = key === 'Documento' || key === 'cpf_cnpj'
        return !isDocumentOrCpfCnpj
      }) as [string, any][]
  )
}

const LabelsWithoutFieldConfig: Partial<Record<ResearchResultsFieldName, string>> = {
  descricao: 'Descrição',
  nome_das_partes: 'Partes envolvidas',
  numero: 'Número',
  tipo_papel: 'Tipo papel',
}

const addressKeys = ['city', 'neighborhood', 'number', 'public_place', 'uf', 'zip_code']

const excludedItemKeysFromDetails = [
  // os campos serão pegos de locationInfo
  'url_uf',
  'url_cidade',
  'url_cartorio',
  'uf_cartorio',
  'cidade_cartorio',
  // campos que não é necessário exibir ou são exibidos de maneira diferente
  'assinantes',
  'id',
  'categoria_id',
  'extras',
  'formato',
  'id',
  'modelo',
  'pesquisa_origem_id',
  'proprietario',
  'result',
  'resultado',
  'service_id',
  'service_category_id',
  'tipo',
  'tipo_pessoa',
  'todos_cartorios_cidade',
  'total_value',
]
