import { Certificate } from 'domain/servicesCBRdoc/config'
import { Fields } from 'domain/servicesCBRdoc/config/serviceFields'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { joinStrings } from 'utils/formatters'

const { MATRICULA, URL_CARTORIO, URL_CIDADE, URL_UF } = FieldName

type CertidaoPreviaMatriculaFields = Record<
  FieldName.URL_CARTORIO | FieldName.URL_CIDADE | FieldName.URL_UF | FieldName.MATRICULA,
  FieldConfig
>

class CertidaoPreviaMatricula extends Certificate {
  formFields: CertidaoPreviaMatriculaFields = {
    url_uf: Fields.url_uf,
    url_cidade: Fields.url_cidade,
    url_cartorio: Fields.url_cartorio,
    matricula: {
      ...Fields.matricula,
      label: 'Matrícula(s)',
      orderNameText: 'Matrícula',
      placeholder: 'Informe uma matrícula por linha',
    },
  }

  constructor() {
    super({
      availableFormatsFields: [URL_CARTORIO, URL_CIDADE, URL_UF],
      id: 55,
      orderNameField: MATRICULA,
      priceByFields: [URL_CARTORIO, URL_CIDADE, URL_UF],
      shortName: 'Prévia de Matrícula',
      steps: ['localizacao', 'dados', 'entrega'],
      splittableField: MATRICULA,
      splittableFieldInOrderName: true,
      extractedReportSections: [
        {
          title: 'Dados do imóvel',
          properties: [
            { label: 'Última atualização', key: 'ultima_atualizacao' },
            { label: 'Tipo de ativo', key: 'tipo_ativo' },
            { label: 'Sub-tipo de ativo', key: 'subtipo_ativo' },
            { label: 'Endereço', key: 'endereco' },
            { label: 'Cidade', key: 'cidade' },
            { label: 'Estado', key: 'unidade_federativa' },
            { label: 'Área', key: 'area' },
            { label: 'Valor do imóvel', key: 'valor' },
          ],
        },
        {
          title: 'Proprietários',
          properties: [
            { label: 'Pessoa / Empresa', key: 'nome', formatter: joinStrings, fromArray: 'proprietarios' },
            { label: 'CPF/CNPJ', key: 'documento', formatter: joinStrings, fromArray: 'proprietarios'  },
          ],
        },
        {
          title: 'Registros de ônus ativos',
          properties: [
            { label: 'Ônus ativos', key: 'gravames_ativos', formatter: joinStrings },
            { label: 'Ônus inativos', key: 'gravames_inativos', formatter: joinStrings },
          ],
        },
      ],
    })
  }
}

const service = new CertidaoPreviaMatricula().initialize()

export default service
