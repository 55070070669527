import { v4 as uuidv4 } from 'uuid'

import {
  CreateOrderFormItem,
  CreateOrderParamsFields,
  CreateOrderState,
  PostData,
} from 'domain/createOrder/types'
import { ResultCode } from 'domain/orders/types'
import { SelectedLocation, ServiceType } from 'domain/servicesCBRdoc/types'
import { FieldConfig, FieldName } from 'domain/servicesCBRdoc/fields/types'
import { ShoppingCartOrder } from 'domain/shoppingCart/types'
import { formatDateToApi, isDateFormatToApi } from 'utils/dateTime'
import { onlyAlphanumeric, onlyNumbers } from 'utils/formatters'
import { getValue, removeEmpties } from 'utils/transformData'

import { ServiceConfig } from '../types'
import CBRdocService from './CBRdocService'

export default class CreateOrderService extends CBRdocService {
  private priceByFields

  constructor(config: ServiceConfig) {
    super(config)

    const { priceByFields } = config

    this.priceByFields = priceByFields ?? []
  }

  private extractServiceFieldParam = (
    apiField: FieldName,
    createOrder: Partial<CreateOrderState>,
    itemId: string | number | undefined = '0'
  ) => {
    const { formItems, selectedEntityType, selectedFormat, selectedLocation } = createOrder

    if (apiField === 'formato') {
      return selectedFormat
    }

    const field = this.formFields[apiField] ?? this.dynamicFields[apiField]

    if (field) {
      if (field.kind === 'location') {
        const data: any = selectedLocation![apiField as keyof SelectedLocation]
        return this.getUrlLocation(apiField, data)
      }

      if (field.stateKey) {
        const selectedField = createOrder[field.stateKey]

        if (this.allowMultipleItems) {
          const itemValue = getValue(formItems?.[itemId]?.[field.stateKey as keyof CreateOrderFormItem])

          if (itemValue !== undefined) {
            return getValue(itemValue)
          }
        }

        return getValue(selectedField) ?? field.defaultValue
      }
    }

    if (apiField === 'tipo_pessoa') {
      return this.allowMultipleItems ? formItems![itemId]?.selectedEntityType : selectedEntityType
    }

    throw new Error(`Campo ${apiField} não configurado.`)
  }

  extractServiceParam = (
    fields: FieldName[],
    createOrder: CreateOrderParamsFields,
    itemId: string | number = '0'
  ) => {
    const allFields = { ...this.formFields, ...this.dynamicFields }
    const params: any = {}

    fields.forEach(name => {
      const apiName = allFields[name]?.apiName ?? name
      params[apiName] = this.extractServiceFieldParam(name, createOrder, itemId)
    })

    return params
  }

  getPriceParams = (createOrder: CreateOrderParamsFields, itemId: number = 0) => {
    return this.extractServiceParam(this.priceByFields, createOrder, itemId)
  }

  private getStateFields = () => {
    return Object.values({ ...this.formFields, ...this.dynamicFields }).filter(field => field.stateKey)
  }

  private getDefaultStateFieldsIfEmpty = (createOrder: Partial<CreateOrderState>) => {
    const data: any = {}

    this.getStateFields().forEach(field => {
      if (createOrder[field.stateKey!] === undefined && field.defaultValue) {
        data[field.stateKey!] = field.defaultValue
      }
    })

    return data
  }

  // ignora os campos que iniciam com id de item,
  // pois queremos só os campos comuns a todos os itens
  private getFormServiceBasicFields = (formData: any, fieldConfigKey: keyof FieldConfig = 'name') => {
    const serviceFieldsNames = this.allFields
      .filter(field => {
        if (field.showIf !== undefined) {
          return field.showIf(formData) || field.dataScope === 'service'
        }

        return field.dataScope === 'service'
      })
      .map(fieldConfig => fieldConfig[fieldConfigKey])

    if (this.autoOrderResults) {
      serviceFieldsNames.push('automaticResults')
    }

    const entries = Object.entries(formData)
      .filter(([field]) => serviceFieldsNames.includes(field as FieldName))
      .map(this.prepareFieldToSubmit)

    return Object.fromEntries(entries)
  }

  private getItemFormData = (formData: any, itemId: number) => {
    const formItemData = this.getFormServiceBasicFields(formData)

    return {
      ...formItemData,
      0: formData[itemId],
    }
  }

  private generateCartItemWithMultipleItems = (createOrder: CreateOrderState) => {
    const items = Object.values(createOrder.formItems)

    return items.map((item, index) => {
      const { countItems, id, orderDetails } = item
      const formData = this.getItemFormData(createOrder.formData, id)

      const itemCreateOrder: CreateOrderState = {
        ...createOrder,
        countItems,
        formData,
        formItems: {
          0: {
            ...item,
            id: 0,
          },
        },
        orderDetails,
      }

      if (index > 0) {
        itemCreateOrder.cartItemId = uuidv4()
      }

      return this.generateCartItem(itemCreateOrder)
    })
  }

  protected generateCartItems = (createOrder: CreateOrderState) => {
    if (this.allowMultipleItems) {
      return this.generateCartItemWithMultipleItems(createOrder)
    }

    return [this.generateCartItem(createOrder)]
  }

  getDefaultLegalEntity = () => {
    if (this.legalEntity) {
      return this.legalEntity === 'juridica' ? 'juridica' : 'fisica'
    }
  }

  private getSubmitDataFromStateFields = (createOrder: Partial<CreateOrderState>) => {
    const data: any = {}

    this.getStateFields().forEach(field => {
      const stateField = createOrder[field.stateKey!]

      if (stateField !== undefined) {
        const value = getValue(stateField)

        if (value !== undefined) {
          data[field.apiName ?? field.name] = value
        }
      }
    })
    const legaLEntityField = this.allFields.find(f => f.name === FieldName.TIPO_PESSOA)
    const showIfLegalEntity =
      !legaLEntityField?.showIf ||
      (legaLEntityField?.showIf && legaLEntityField.showIf(createOrder.formData!))
    if (this.legalEntity && showIfLegalEntity) {
      data[TIPO_PESSOA] = createOrder.selectedEntityType ?? this.getDefaultLegalEntity()
    }

    return data
  }

  protected getSubmitLocation = (selectedLocation: SelectedLocation) => {
    if (!this.formFields.url_uf) return {}

    const { url_cartorio, url_cidade, url_uf } = selectedLocation
    const location: any = {}

    if (url_uf) {
      location[FieldName.URL_UF] = this.getUrlLocation(FieldName.URL_UF, url_uf)
    }

    if (url_cidade) {
      location[FieldName.URL_CIDADE] = this.getUrlLocation(FieldName.URL_CIDADE, url_cidade)
    }

    if (url_cartorio) {
      location[FieldName.URL_CARTORIO] = this.getUrlLocation(FieldName.URL_CARTORIO, url_cartorio)
    }

    return location
  }

  protected extractFileUploadValues(value: unknown) {
    return typeof value !== 'string' && typeof value === 'object' && (value as any).fileUploadId
      ? (value as any).fileUploadId
      : value
  }

  protected prepareFieldToSubmit = ([fieldName, value]: [any, any]) => {
    const config = this.getField(fieldName)

    if (config) {
      if (config.kind === 'select') {
        if (value && value.value !== undefined) {
          value = value.value
        }
      } else if (config.kind === 'date') {
        if (!isDateFormatToApi(value)) {
          value = formatDateToApi(value as string)
        }
      } else if (config.apiRemoveMask) {
        value = onlyAlphanumeric(value as string)
      }

      if (config.kind === 'upload') {
        if (Array.isArray(value)) {
          value = value.map(v => this.extractFileUploadValues(v))
        } else {
          value = this.extractFileUploadValues(value)
        }
      }

      if (config.name === FieldName.NOME_ITEM_PEDIDO) {
        value = value.slice(0, 120)
      }

      if (config.apiName) {
        fieldName = config.apiName as any
      }
    }

    return [fieldName, value]
  }

  protected getSubmitData = (createOrder: CreateOrderState) => {
    const {
      dynamicFields,
      files,
      formData,
      formItems,
      origin,
      selectedExtras,
      selectedLocation,
      selectedFormat,
      selectedService,
    } = createOrder

    const submitFiles = files ? files : formData.arquivos
    let submitData: PostData = {}

    if (this.allowMultipleItems) {
      submitData = this.getFormServiceBasicFields(formData, 'apiName')
    } else {
      const entries = Object.entries(formData).map(this.prepareFieldToSubmit)
      submitData = Object.fromEntries(entries)
    }

    const extras =
      selectedFormat !== 'email' || selectedService!.type === ServiceType.DILIGENCE ? selectedExtras : null

    submitData = {
      ...submitData,
      origin: origin.description,
      formato: selectedFormat || 'email',
      service_id: selectedService.id,
      service_category_id: selectedService.categoryId,
      ...this.getSubmitDataFromStateFields(createOrder),
      ...extras,
    }

    if (origin.researchId) {
      submitData.pesquisa_origem_id = origin.researchId
    }

    const location = this.getSubmitLocation(selectedLocation)

    if (location) {
      submitData = { ...submitData, ...location }
    }

    if (this.steps.includes('entrega')) {
      submitData[FieldName.FORMATO] = selectedFormat ?? 'email'
    }

    if (formData.automaticResults && Array.isArray(formData.automaticResults)) {
      submitData.auto_purchase_certificate_from_result_positive = formData.automaticResults.includes(
        ResultCode.POSITIVE
      )
      submitData.auto_purchase_certificate_from_result_negative = formData.automaticResults.includes(
        ResultCode.NEGATIVE
      )
    }

    if (this.allowMultipleItems) {
      if (formData[0]) {
        submitData = {
          ...submitData,
          ...this.getFormServiceBasicFields(formData[0], 'apiName'),
        }

        const {
          endereco_cidade,
          endereco_complemento,
          endereco_bairro,
          endereco_numero,
          endereco_estado,
          endereco_logradouro,
          cep,
          ...dataToSubmit
        } = formData[0] as Partial<Record<FieldName, any>>

        const addressFields = this.allFields.filter(field => {
          return (
            field.kind === 'fullAddress' &&
            (this.formFields[field.name] || dynamicFields?.includes(field.name))
          )
        })

        if (addressFields.length > 0) {
          const fullAddress = [
            endereco_cidade,
            endereco_complemento,
            endereco_bairro,
            endereco_numero,
            endereco_estado,
            endereco_logradouro,
            cep,
          ]
            .filter(Boolean)
            .join(', ')

          addressFields.forEach(field => {
            submitData[field.apiName] = fullAddress
          })

          if (this.allFields.some(field => field.name === 'cep')) {
            const zipcodeField = this.getField('cep')!
            submitData[zipcodeField.apiName] = onlyNumbers(cep)
          }
        }

        if (dynamicFields?.includes(REGISTRADO_ENDERECO_CEP)) {
          submitData[REGISTRADO_ENDERECO_CEP] = onlyNumbers(cep)
        }

        submitData = {
          ...dataToSubmit,
          ...submitData,
        }

        if (formItems[0].selectedEntityType) {
          submitData.tipo_pessoa = formItems[0].selectedEntityType
        }

        const fieldUpload = Object.values(this.formFields).find(o => o.kind === 'upload')
        const hasUploadField = this.steps.includes('upload') || fieldUpload

        if (hasUploadField) {
          if (fieldUpload) {
            if (submitFiles && !fieldUpload.uploadMultiple) {
              submitData[fieldUpload.apiName] = submitFiles?.[0]?.fileUploadId
            } else if (submitFiles && submitFiles?.length > 0) {
              submitData[fieldUpload.apiName] = submitFiles.map((f: any) => {
                if (typeof f === 'string') {
                  return f
                }
                return f.fileUploadId
              })
            }
          }
        }
      }
    }

    return this.prepareSubmitDataToApi(submitData)
  }

  prepareSubmitDataToApi = (submitData: Partial<Record<string, any>>) => {
    const entries = Object.entries(submitData).map(this.prepareFieldToSubmit)
    const validEntries = entries.filter(([key]) => {
      const field = Object.values(this.formFields).find(f => f.apiName === key)
      if (!field) return true
      if (field.showIf !== undefined) return field.showIf(submitData)
      return true
    })
    return removeEmpties(Object.fromEntries(validEntries))
  }

  generateCartItem = (createOrder: CreateOrderState): ShoppingCartOrder => {
    const createOrderItem = this._generateCreateOrderState({
      ...createOrder,
      ...this.getDefaultStateFieldsIfEmpty(createOrder),
    }) as ShoppingCartOrder

    createOrderItem.submitData = this.getSubmitData(createOrderItem)

    return createOrderItem
  }

  protected _generateCreateOrderState = (createOrder: CreateOrderState, createCartId = false) => {
    const createOrderItem: CreateOrderState = {
      ...createOrder,
      countItems: 1,
      createStatus: 'review',
    }

    if (!createOrderItem.cartItemId || createCartId) {
      createOrderItem.cartItemId = uuidv4()
    }

    return createOrderItem
  }
}

const { REGISTRADO_ENDERECO_CEP, TIPO_PESSOA } = FieldName
