import { Form, Spin } from 'antd'
import { Section } from 'layouts'
import { Fields } from 'domain/servicesCBRdoc'
import { RadioGroupField } from 'domain/servicesCBRdoc/fields'
import { SubmitButton } from 'ui'
import { useDispatch } from 'react-redux'
import { useQueryClient } from 'react-query'
import { createOrderActions, useCreateOrderNextStep, useCreateOrderState } from 'domain/createOrder/document'
import { createUseLoadUFModelsKey } from 'domain/createOrder/document/services/useLoadUFModels/key'
import { getUFModels } from 'domain/createOrder/document/services/useLoadUFModels/request'
import { useLoadUFInstances } from 'domain/createOrder/document/services/useLoadUFInstances'

export const SelectInstanceStep = () => {
  const { selectedService, selectedLocation, selectedInstance } = useCreateOrderState()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [goToNextStep] = useCreateOrderNextStep('instancia')

  const final_uf = Array.isArray(selectedLocation?.url_uf)
    ? selectedLocation?.url_uf?.[0].value
    : selectedLocation?.url_uf?.value

  const { data, isLoading, isError } = useLoadUFInstances({
    service: selectedService,
    params: { uf: final_uf as string },
    enabled: !!final_uf,
  })

  const handleChange = (value: string) => {
    dispatch(createOrderActions.setSelectedInstance(value))
    dispatch(createOrderActions.setSelectedModel(undefined))

    queryClient.prefetchQuery(createUseLoadUFModelsKey(selectedService.id, final_uf as string, value), () =>
      getUFModels({ service: selectedService, uf: final_uf as string, instance: value })
    )
  }

  return (
    <Spin spinning={isLoading}>
      <Section heading="Selecione a instância judicial do processo" className="pt-4">
        <Form
          form={form}
          initialValues={{
            [Fields.instancia.name]: selectedInstance,
          }}
          layout="vertical"
          onFinish={goToNextStep}
          className="pb-24 pt-4"
          requiredMark={false}
        >
          <RadioGroupField
            field={{ ...Fields.instancia, label: '' }}
            onChange={handleChange}
            options={data}
            hasError={isError}
            required
          />
          <SubmitButton disabled={!selectedInstance} />
        </Form>
      </Section>
    </Spin>
  )
}
