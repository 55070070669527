import { RadioGroupField, SelectField } from 'domain/servicesCBRdoc/fields'
import { FieldConfig } from 'domain/servicesCBRdoc/fields/types'

import CheckboxGroupField from './CheckboxGroupField'
import DynamicTextField from './DynamicTextField'
import FederativeUnitsField from './FederativeUnitsField'

interface Props {
  field: FieldConfig
  spinning?: boolean
  labelInValue?: boolean
  itemId?: number
  autoFocus?: boolean
  onChangeSelectable?: (value: any) => void
}

export default function DynamicField({ labelInValue, onChangeSelectable, ...props }: Props) {
  const { field } = props

  if (field.kind === 'select' || field.kind === 'uf') {
    const selectableProps = { labelInValue, onChange: onChangeSelectable, ...props }

    if (field.kind === 'uf') {
      return <FederativeUnitsField {...selectableProps} />
    }

    return <SelectField {...selectableProps} />
  }

  if (field.kind === 'checkboxGroup') {
    return <CheckboxGroupField onChange={onChangeSelectable} {...props} />
  }

  if (field.kind === 'radio') {
    return <RadioGroupField onChange={onChangeSelectable} {...props} />
  }

  return <DynamicTextField {...props} />
}
