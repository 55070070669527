import { CreateOrderForm, Extras } from 'domain/createOrder/types'
import { GroupAPI } from 'domain/groups/types'
import { ExplorerItemAPI, OCRDocumentAPI } from 'domain/myFiles/typesAPI'
import { OrderAPI, ResultCode, StatusCode } from 'domain/orders/types'
import { LocationInfoAPI } from 'domain/orders/typesAPI'
import { FieldName } from 'domain/servicesCBRdoc/fields/types'
import { CategoryAPI, ServiceAPI } from 'domain/servicesCBRdoc/types'
import { User } from 'domain/users/types'

export type PlaceOrderDefaultValuesAPI = Record<ResultCode, PlaceOrderDefaultValuesServiceAPI>

export interface PlaceOrderDefaultValuesServiceAPI {
  service: ServiceAPI
  service_category: CategoryAPI
  data: CreateOrderForm
}

export const ExtractedSummaryProcessLabels: { [key in string]: string } = {
  foro: 'Foro',
  vara: 'Vara',
  tipo_processo: 'Tipo',
  requerido: 'Requerido',
  requerente: 'Requerente',
}
export type ExtractedSummaryOwners = {
  nome: string
  documento: string
}

export type ExtractedSummaryProcesses = {
  situacao?: string | null
  classe?: string | null
  assunto?: string | null
  apelantes?: string | null
  vara?: string | null
  numero?: string | null
  orgao_julgador?: string | null
  foro?: string
  status?: string
  comarca?: string
  requerido?: string
  requerente?: string
  tipo_processo?: string
  numero_processo?: string
  data_distribuicao?: string
}

export type ExtractedSummary = {
  area: {
    total: string | null
    perimetro: string | null
    privativa: string | null
    comum: string | null
    util: string | null
    construida: string | null
  } | null
  cidade?: string
  tipo_ativo?: string
  valor?: string
  endereco?: string
  subtipo_ativo?: string
  ultima_atualizacao?: string
  gravames_ativos?: string[]
  gravames_inativos?: string[]
  unidade_federativa?: string
  nomes_proprietarios?: string[]
  documentos_proprietarios?: string[]
  classes?: string | null,
  assuntos?: string | null,
  apelantes?: string | null,
  nome_reus?: string[] | null,
  situacoes?: string | null,
  data_emissao?: string | null,
  varas_processos?: string | null
  numeros_processos?: string | null,
  orgaos_julgadores?: string[] | null 
  datas_distribuicao_processos?: string | null
  declaracao_inexistencia_processos?: string | null 
  proprietarios?: ExtractedSummaryOwners[]
  status_certidao?: string | null
  processos?: ExtractedSummaryProcesses[]
}

export interface OrderItemAPI extends Extras {
  ai_service_name?: string
  annotations: string | null
  auto_purchase_certificate_from_result_positive: boolean
  auto_purchase_certificate_from_result_negative: boolean
  backoffice_id: number
  can_accept_additional_information: boolean
  detailed_service_data: CreateOrderForm | null // pedidos importados não tem detailed_service_data
  estimated_at: string
  explorer_item?: ExplorerItemAPI
  extracted_summary?: ExtractedSummary | null
  file_preview_url: string | null
  finished_at: string | null
  groups?: GroupAPI[]
  has_ai_extracted_data: boolean
  has_ai_analysis_pending: boolean
  has_consumed_ai_free_analysis: boolean
  has_extras: boolean
  id: number
  imported_at?: string
  index_in_purchase: number
  last_status_change_at?: string
  location_info: LocationInfoAPI | null
  name: string
  next_order_id_same_purchase: number | null
  ocr: OCRDocumentAPI | null
  place_order_default_values: PlaceOrderDefaultValuesAPI
  placed_at: string
  previous_order_id_same_purchase: number | null
  purchase: OrderAPI
  purchase_id: number
  register: string
  refundable: boolean
  refundable_value?: number
  rejected_reason: string | null
  result: ResultCode | null
  result_details: ResearchResultAPI[] | null
  service: ServiceAPI
  service_category: CategoryAPI
  service_category_id: number
  status: StatusCode
  status_details: string | null
  total_cost: number
  total_estimated_cost_postpaid_customer?: number
  user: User
  valid_until?: string
  verification_code?: string
}

export type ResearchResultAPI = Partial<
  Record<FieldName | 'cartorio' | 'cidade' | 'data_resultado' | 'uf', any>
>

export type SelectedLocationAPI = {
  url_uf?: string | string[]
  url_cidade?: string
  url_cartorio?: string | string[]
}

export interface SignaturesProgressAPI {
  documento: string
  nome: string
  email: string
  assinado: boolean
}
