import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { RoutePaths } from 'app/routes'
import { Kits } from 'domain/kits'
import { BasicLayout, LearnEverythingSubtitle } from 'layouts'
import { removeStorageItem } from 'utils/localStorage'
import { Button } from 'ui'

export default function KitsPage() {
  useEffect(() => {
    removeStorageItem('createKit')
  }, [])

  return (
    <BasicLayout
      pageTitle="Meus kits"
      subTitle={
        <LearnEverythingSubtitle subtitle="Entenda como funciona o kit" href={RoutePaths.KITS_TUTORIAL} />
      }
      extra={<Link to={RoutePaths.CREATE_KIT} component={NewKitButton} />}
    >
      <main className="flex flex-1 bg-gray-100 flex-col overflow-x-auto px-8">
        <Kits />
      </main>
    </BasicLayout>
  )
}

function NewKitButton() {
  return (
    <Button href={RoutePaths.CREATE_KIT} icon="plus-circle" iconStroke={2} className="rounded font-semibold text-white">
      Criar novo Kit
    </Button>
  )
}
