import React from 'react'

import { Icon } from 'ui'
import { HelpLine } from 'domain/app'
import { useGetOrderItem } from '../../state/viewOrderItem'
import { useIsCustomerPostPaid } from 'domain/customers'

import ProvideAdditionalInformation from '../actions/ProvideAdditionalInformation'
import RequestCancellation from '../actions/RequestCancellation'
import RejectedReason from '../details/RejectedReason'
import RequestRefund from '../refund/RequestRefund'

function ViewOrderRejected() {
  const orderItem = useGetOrderItem()
  const isCustomerPostPaid = useIsCustomerPostPaid()

  return (
    <div className="pr-6 pl-8 flex flex-col flex-1 items-center bg-gray-100">
      <div className="w-full bg-white flex flex-col items-center rounded-lg gap-8 mb-4 py-14">
        <Icon name="warning-file"/>

        <h1 className="font-bold text-2xl text-center mb-3">Ação Necessária</h1>

        <p className="text-center">
          Por favor, verifique o motivo informado abaixo e escolha a melhor opção para o andamento de seu
          pedido.
        </p>

        <RejectedReason
          warning
          reason={
            orderItem.rejectedReason ||
            'As informações fornecidas foram insuficientes para a localização do documento solicitado'
          }
        />

        {orderItem.canAcceptAdditionalInformation && (
          <p className="mb-4 text-center px-6">
            Você poderá fornecer informações adicionais para realizarmos uma nova busca.
          </p>
        )}

        {orderItem.canAskRefund && (
          <p className="mb-4 text-center w-2/3 mx-auto">
            Os créditos referentes a esse pedido estão disponíveis para reembolso, com o desconto da taxa de
            serviço. Se tiver dúvidas, conte com o suporte na nossa equipe.
          </p>
        )}

        <div
          className={`grid grid-cols-1 gap-3 lg:max-w-3xl xl:max-w-2xl empty:hidden
          ${(isCustomerPostPaid || orderItem.canAskRefund) && 'xl:grid-cols-2'}`}
        >
          {orderItem.canAcceptAdditionalInformation && <ProvideAdditionalInformation />}

          {isCustomerPostPaid ? <RequestCancellation /> : orderItem.canAskRefund && <RequestRefund />}
        </div>

        <HelpLine />
      </div>
    </div>
  )
}

export default ViewOrderRejected
