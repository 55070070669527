import { useMemo } from 'react'

import { ShoppingStep } from 'domain/createOrder/types'
import { getService, useServiceById } from 'domain/servicesCBRdoc'
import { ServiceType } from 'domain/servicesCBRdoc/types'
import { useCreateOrderState } from './createOrderSlice'

export default function useCreateOrderSteps() {
  const { dynamicFields, formItems, selectedModel, selectedService, selectedType } = useCreateOrderState()
  const currentService = useServiceById(selectedService.id)

  return useMemo(() => {
    const { steps } = getService(selectedService.code!)
    const orderSteps: Partial<Record<ShoppingStep, string>> = {}

    const getStepText = (step: ShoppingStep) => {
      return StepsTitle[step]![selectedService.type!] ?? StepsTitle[step]!.label
    }

    if (currentService && currentService.category1Id && currentService.category2Id) {
      orderSteps.categoria = 'Categoria'
    }

    const stepLabels = ['localizacao', 'instancia', 'modelo', 'entidade', 'upload', 'dados']

    stepLabels.forEach((step: any) => {
      if (steps.includes(step)) {
        orderSteps[step as ShoppingStep] = getStepText(step)
      }
    })

    // RTD e RGI
    if (selectedModel === 'com-assinaturas') {
      orderSteps['assinantes'] = 'Assinantes'
    }

    if (steps.includes('entrega')) {
      orderSteps.entrega = 'Entrega'
    }

    return orderSteps
  }, [dynamicFields, formItems, selectedService, selectedType])
}

type StepDetails = { label: string } & { [key in ServiceType]?: string }
type StepsLabels = Partial<Record<ShoppingStep, StepDetails>>

const StepsTitle: StepsLabels = {
  localizacao: {
    label: 'Localização',
    [ServiceType.DOCUMENT_REGISTRATION]: 'Tipo de Registro',
  },
  instancia: {
    label: 'Instância',
  },
  modelo: {
    label: 'Modelo',
  },
  entidade: {
    label: 'Entidade',
  },
  dados: {
    label: 'Dados',
    [ServiceType.DOCUMENT_REGISTRATION]: 'Detalhes',
  },
  upload: {
    label: 'Upload',
  },
}
