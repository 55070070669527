import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { NamePath } from 'antd/lib/form/interface'

import { Routes } from 'app/routes'
import { createOrderActions } from 'domain/createOrder/document'
import { ResearchCode } from 'domain/servicesCBRdoc/types'
import { SplittableTextInput } from 'ui'

import config from '../certidaoJuntaComercialConfig'

interface Props {
  name: NamePath
  onChangeValue?: (value: string[]) => void
  type: string | undefined
  required?: boolean
}

export default function CertidaoJuntaComercialActNumberInput({ name, required, type, onChangeValue }: Props) {
  if (!type || type === 'simples') return null

  return (
    <SplittableTextInput
      name={name}
      maxLength={numero_ato.maxLength!}
      label={<ActNumberInputLabel required={required} />}
      placeholder="Informe um ato por linha"
      onChangeValue={onChangeValue}
      required={required}
    />
  )
}

interface ActNumberInputLabelProps {
  required?: boolean
}

function ActNumberInputLabel({ required }: ActNumberInputLabelProps) {
  const history = useHistory()
  const dispatch = useDispatch()

  const onLabelClick = () => {
    dispatch(createOrderActions.resetState())
    history.push(Routes.CreateOrder(ResearchCode.PESQUISA_JUNTA_COMERCIAL))
  }

  return (
    <p>
      Número do ato (caso não saiba, faça uma{' '}
      <b className="cursor-pointer hover:underline text-primary hover:text-opacity-75" onClick={onLabelClick}>
        nova solicitação de pesquisa
      </b>{') '}
      {!required && '(Opcional)'}
    </p>
  )
}

const { numero_ato } = config.formFields
