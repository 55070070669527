import React from 'react'

import { useHasPermission } from 'domain/auth'
import { AlertNoOrders, AlertWithOrderButton } from 'domain/orders'

function EmptyCart() {
  const canOrderServices = useHasPermission('canOrderServices')

  return (
    <div className="p-7 pt-0 pb-24 bg-white overflow-x-auto flex-1">
      {canOrderServices ? (
        <AlertNoOrders
          title="Você ainda não tem nada no seu carrinho"
          description="Pronto para começar? Clique no botão abaixo para fazer sua primeira solicitação."
        />
      ) : (
        <AlertWithOrderButton
          title="Você não tem permissão para fazer pedidos"
          description="Seu usuário não tem permissão para fazer pedidos. Entre em contato com o administrador da sua conta para solicitação de acesso."
        />
      )}
    </div>
  )
}

export default EmptyCart
