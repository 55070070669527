import React from 'react'

import {
  CreateOrderFlow,
  CreateOrderFlowProps,
  CreateOrderForm,
  MultipleItemsSidebar,
  useCreateOrderState,
} from 'domain/createOrder/document'

interface Props extends Omit<CreateOrderFlowProps, 'dataStep' | 'sidebar'> {
  renderFormItem: (itemId: number) => JSX.Element
  title?: string
  header?: React.ReactNode
  disableMultiItems?: boolean
  noRenderOrderName?: boolean
  noReplicateData?: boolean
}

export default function CertificateFlow({
  title = 'Dados da certidão',
  header,
  renderFormItem,
  disableMultiItems,
  noRenderOrderName,
  noReplicateData,
  ...props
}: Props) {
  const { formData } = useCreateOrderState()

  return (
    <CreateOrderFlow
      sidebar={<MultipleItemsSidebar />}
      dataStep={
        <CreateOrderForm
          sectionTitle={title}
          header={header}
          initialValues={formData}
          renderFormItem={renderFormItem}
          noRenderOrderName={noRenderOrderName}
          disableMultiItems={disableMultiItems}
          noReplicateData={noReplicateData}
        />
      }
      {...props}
    />
  )
}
