import { Button, Form } from 'antd'
import { useMemo, useState } from 'react'

import { KitItem } from 'domain/kits/types'
import { useAddKitFormToCart } from '../../services'
import {
  useCommonForm,
  useGetDetailsFieldsConfig,
  useGetKit,
  useGetLegalEntity,
  useGetServicesForm,
  useSaveCommonFormOnLocalStorage,
} from '../../state'
import DetailsServiceFields from '../fields/DetailsServiceFields'
import GenericField from '../fields/GenericPersonalFields'
import OtherServiceFields from '../fields/OtherServiceFields'
import KitSimilarOrdersModal from '../KitSimilarOrdersModal'

export default function DetailsForm() {
  const [form] = Form.useForm()
  const [addingAnotherPerson, setAddingAnotherPerson] = useState(false)
  const [formCommonData, setFormCommonData] = useCommonForm()
  const { addToCart, loading, similarOrders, setSimilarOrders, onResetOrRedirect } = useAddKitFormToCart(form)
  const saveOnLocalStorage = useSaveCommonFormOnLocalStorage()
  const fields = useGetDetailsFieldsConfig()
  const formServicesData = useGetServicesForm()
  const kit = useGetKit()
  const legalEntity = useGetLegalEntity()

  const onFinish = (formCommonData: any, addAnotherPerson: boolean) => {
    setAddingAnotherPerson(addAnotherPerson)
    setFormCommonData(formCommonData)
    addToCart({
      addAnotherPerson,
      checkSimilars: true,
      formCommonData,
      formServicesData,
      items: kit.items,
    })
  }

  const onFinishOrder = (formCommonData: any) => {
    onFinish(formCommonData, false)
  }

  const onAddOtherPerson = async () => {
    try {
      await form.validateFields()
      onFinish(form.getFieldsValue(), true)
    } catch (err) {
      console.error(err)
    }
  }

  const registreeFields = useMemo(() => {
    if (legalEntity === 'juridica') {
      const nameFieldIndex = fields.registreeFields.findIndex(field => field.name === 'nome_pj')
      const cnpjFieldIndex = fields.registreeFields.findIndex(field => field.name === 'cnpj')

      if (nameFieldIndex !== -1 && cnpjFieldIndex !== -1) {
        const pjFields = [...fields.registreeFields]
        pjFields[cnpjFieldIndex].kind = 'cpfOrCnpjAndName'
        pjFields.splice(nameFieldIndex, 1)
        return pjFields
      }
    }

    return fields.registreeFields
  }, [fields.registreeFields, legalEntity])

  return (
    <div className="md:max-w-4xl lg:max-w-lg xl:max-w-xl">
      <Form
        form={form}
        initialValues={formCommonData}
        layout="vertical"
        onFinish={onFinishOrder}
        onValuesChange={saveOnLocalStorage}
        requiredMark={false}
        scrollToFirstError
      >
        {registreeFields.map((field, index) => (
          <GenericField key={field.name} config={field} autoFocus={index === 0} />
        ))}

        {fields.serviceFields.map(config => (
          <DetailsServiceFields key={config.kitItem.id} config={config} />
        ))}

        {kit.items.map(kitItem => (
          <OtherServiceFields key={kitItem.id} kitItem={kitItem} fields={fields} />
        ))}

        {(!loading || addingAnotherPerson) && (
          <Button
            onClick={onAddOtherPerson}
            loading={loading}
            type="primary"
            ghost
            size="large"
            block
            className="mt-1 mb-3"
          >
            Adicionar outra {legalEntity === 'juridica' ? 'Empresa' : 'Pessoa'}
          </Button>
        )}

        {(!loading || !addingAnotherPerson) && (
          <Button htmlType="submit" loading={loading} type="primary" size="large" block>
            Finalizar
          </Button>
        )}
      </Form>

      <KitSimilarOrdersModal
        addToCart={(itemsToAdd: KitItem[]) =>
          addToCart({
            addAnotherPerson: addingAnotherPerson,
            items: itemsToAdd,
            formCommonData,
            formServicesData,
          })
        }
        loading={loading}
        similarOrders={similarOrders}
        open={similarOrders.length > 0}
        onClose={() => setSimilarOrders([])}
        onResetOrRedirect={() => onResetOrRedirect(addingAnotherPerson)}
      />
    </div>
  )
}
