import { API } from 'api/network/v2'
import { SelectedService } from 'domain/createOrder/types'

interface RequestParams {
  service: Pick<SelectedService, 'categoryId' | 'id'>
  uf: string
}

export const getUFInstances = async ({ service, uf }: RequestParams) => {
  const response = await API.post(
    `/services/${service.id}/categories/${service.categoryId}/extra-informations/instancias`,
    {
      detailed_service_data: {
        url_uf: uf,
      },
    }
  )

  return response.data
}
