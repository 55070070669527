import {
  useGetOrderItem,
} from 'domain/orderItem/shared'
import { ExcelGenerator } from 'domain/orderItem/types'
import ExportFile from './ExportFile'

export default function ViewResearchActions({ excel }: { excel: ExcelGenerator }) {
  const orderItem = useGetOrderItem()

  return (
    <ExportFile excel={excel} orderItem={orderItem} />
  )
}
