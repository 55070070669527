import { Spin } from 'antd'

import { DynamicField } from 'domain/servicesCBRdoc/fields'
import { FieldConfig } from 'domain/servicesCBRdoc/fields/types'

interface Props {
  loading: boolean
  itemId: number
  fields: FieldConfig[]
}

export default function CertidaoJusticaEstadualFields({ itemId, loading, fields }: Props) {
  return (
    <Spin spinning={loading}>
      {fields.map(field => (
        <DynamicField key={field.apiName} field={field} itemId={itemId} labelInValue={false} />
      ))}
    </Spin>
  )
}
