import React, { ReactNode, Ref } from 'react'

import { FeedbackMessage } from 'domain/app'
import { StatusCode } from 'domain/orders/types'
import { Icon } from 'ui'

import { useGetOrderItem } from '../../state/viewOrderItem'
import RejectedReason from '../details/RejectedReason'
import ViewOrderPayment from './ViewOrderPayment'
import ViewOrderProcessing from './ViewOrderProcessing'
import ViewOrderRejected from './ViewOrderRejected'
import ViewOrderApproval from './ViewOrderApproval'
import ViewOrderUnavailable from './ViewOrderUnavailable'

interface Props {
  embedRef?: Ref<any>
  children?: ReactNode
}

const ViewOrderStatus = ({ children }: Props): any => {
  const orderItem = useGetOrderItem()
  const statusCode = orderItem.status
  
  if (statusCode === StatusCode.AGUARDANDO_APROVACAO) {
    return <ViewOrderApproval />
  }  

  if (statusCode === StatusCode.AGUARDANDO_PAGAMENTO) {
    return <ViewOrderPayment />
  }

  if (statusCode === StatusCode.PROCESSANDO) {
    return <ViewOrderProcessing />
  }

  if (statusCode === StatusCode.ACAO_NECESSARIA) {
    return <ViewOrderRejected />
  }

  if (statusCode === StatusCode.INDISPONIVEL) {
    return <ViewOrderUnavailable />
  }

  if (orderItem.status === StatusCode.CANCELADO) {
    return (
      <FeedbackMessage
        status={statusCode}
        alertType="error"
        icon={<Icon name="error-file" className="mt-8" />}
      >
        <p className="text-base">Este pedido foi cancelado pelo seguinte motivo:</p>

        {orderItem.rejectedReason && <RejectedReason reason={orderItem.rejectedReason} />}
      </FeedbackMessage>
    )
  }

  return children
}

export default ViewOrderStatus
