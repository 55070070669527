import React, { useEffect, useMemo, useState } from 'react'
import { Checkbox, Form } from 'antd'

import { ResultCode } from 'domain/orders/types'

function AutoPurchaseFromResearchResult({ automaticResults }: { automaticResults: ResultCode[] }) {
  const form = Form.useFormInstance()
  const checkedList = form.getFieldValue('automaticResults')
  const options = useMemo(() => {
    if (!automaticResults || !Array.isArray(automaticResults)) return []
    const options = automaticResults.map(result => {
      if (result === ResultCode.POSITIVE) {
        return { label: 'Resultados positivos', value: ResultCode.POSITIVE }
      }

      return { label: 'Resultados negativos', value: ResultCode.NEGATIVE }
    })

    return options
  }, [automaticResults])


  if (!options || options.length === 0) {
    return null
  }

  return (
    <>
      <hr className="my-6" />

      <Form.Item
        label={
          <h3 className="text-xl text-secondary-500 mb-3">
            Solicitar automaticamente os resultados da Pesquisa
          </h3>
        }
        name="automaticResults"
        valuePropName="checked"
        className="flex flex-col gap-3"
      >
        <Checkbox.Group options={options} value={checkedList} />
      </Form.Item>
    </>
  )
}

export default AutoPurchaseFromResearchResult
