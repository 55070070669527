import { API } from 'api/network/v2'
import { LegalEntity } from 'domain/app/types'
import { SelectedService } from 'domain/createOrder/types'

interface RequestParams {
  service: Pick<SelectedService, 'categoryId' | 'id'>
  uf: string | number
  instance: string
  model: string | number
  entity: LegalEntity
}

export const getNacionalities = async ({ service, uf, instance, model, entity }: RequestParams) => {
  const response = await API.post(
    `/services/${service.id}/categories/${service.categoryId}/extra-informations/nacionalidades`,
    {
      detailed_service_data: {
        url_uf: uf,
        instancia: instance,
        modelo: model,
        tipo_pessoa: entity,
      },
    }
  )

  return response.data
}
